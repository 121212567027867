import React, { FunctionComponent } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import TMainCategory from './WhiteSpace/TMainCategory';
import TSubCategory from './WhiteSpace/TSubCategory';
import TTechnology from './WhiteSpace/TTechnology';
import ViewTaxonomy from './WhiteSpace/ViewTaxonomy';
import TTaggingDetails from './WhiteSpace/TTaggingDetails';
import TCompaniesByCompany from '../../components/TCompaniesByCompany';
import CompanyDetails from './Companies/Companies';
import Research from './Research';
import ForgePontTabs from '../../components/Tabs';
import Flags from './Flags';
import TopicInsights from './TopicInsights';
import TFinancings from './Financings';
import Coverage from './Coverage';
import Board from '../../components/DragAndDrop';

interface ISourcingProps {
    loading: boolean;
}

const Sourcing: FunctionComponent<ISourcingProps> = ({ loading }) => {
    return (<>
        <ForgePontTabs mainPath="sourcing">
            <Switch>

                {/* Companies  */}
                <Route path="/sourcing/companies/company" component={TCompaniesByCompany} />
                <Route path="/sourcing/companies/sweet-spot" component={TCompaniesByCompany} />
                <Route path="/sourcing/companies/priority-backlog" component={TCompaniesByCompany} />
                <Route path="/sourcing/companies/trouble-breaking-in" component={TCompaniesByCompany} />
                <Route path="/sourcing/companies/needs-primary-contact" component={TCompaniesByCompany} />
                <Route path="/sourcing/companies/fpci" component={TCompaniesByCompany} />
                <Route path="/sourcing/companies/recent-updates" component={TCompaniesByCompany} />
                <Route path="/sourcing/companies/details/:id" component={CompanyDetails} />

                <Route path="/sourcing/whitespace/category" component={TMainCategory} />
                <Route path="/sourcing/whitespace/subcategory" component={TSubCategory} />
                <Route path="/sourcing/whitespace/technology" component={TTechnology} />
                <Route path="/sourcing/whitespace/tagging-details" component={TTaggingDetails} />
                <Route path="/sourcing/whitespace/taxonomy" component={ViewTaxonomy} />

                <Route path="/sourcing/research" component={Research} />
                <Route path="/sourcing/coverage" component={Coverage} />
                <Route path="/sourcing/hygiene" component={Flags} />
                <Route path="/sourcing/opportunities" component={Board} />
                <Route path="/sourcing/financings/all" component={TFinancings} />
                <Route path="/sourcing/financings/equity" component={TFinancings} />
                <Route path="/sourcing/financings/m&a" component={TFinancings} />
                <Route path="/sourcing/financings/out-of-business" component={TFinancings} />
                <Route path="/sourcing/financings/last-week-financing" component={TFinancings} />
                <Route path="/sourcing/financings/last-week-ma" component={TFinancings} />
                <Route path="/sourcing/topicInsights" component={TopicInsights} />

                {/* Redirections  */}
                <Route path="/sourcing/financings">
                    <Redirect to="/sourcing/financings/all" />
                </Route>
                <Route path="/sourcing/whitespace">
                    <Redirect to="/sourcing/whitespace/category" />
                </Route>
                <Route path="/sourcing/companies">
                    <Redirect to="/sourcing/companies/company" />
                </Route>
                <Route path="/sourcing">
                    <Redirect to="/sourcing/companies" />
                </Route>
            </Switch>
        </ForgePontTabs>
    </>);
};
export default Sourcing;
