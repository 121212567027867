import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Tooltip } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IconButton from "@material-ui/core/IconButton";
import GenericTable from "../../../../components/GenericTable";
import TSkeleton from "../../../../components/TSkeleton";
import { selectDashboardInvestors } from "../../../../store/dashboard/dashboardSlice";
import { getDashboardInvestors } from "../../../../store/dashboard/dashboardThunks";

import styles from "./styles.module.scss";
import usePagination from "../../../../hooks/usePagination";
import Pagination from "../../../../components/Pagination/Pagination";
import { defaultColumn } from "../../../Sourcing/utils";
import { formatDate } from "../../../../utils/formatting";
import OutlookModal from "../../../../components/OutlookModal";

export default function DashboardInvestors() {
  const dispatch = useDispatch();
  const [isOutlookModalOpen, setIsOutlookModalOpen] = useState(false);
  const [currentInvestorId, setCurrentInvestorId] = useState("");

  const { rows, cells, isLoading } = useSelector(selectDashboardInvestors);

  useEffect(() => {
    dispatch(getDashboardInvestors());
  }, []);

  const getCellStyle = (cell: any, index: number) => {
    return cell === "Status"
      ? {
          color: `#${
            rows[(currentPage - 1) * 5 + index]["Status Text Color"]
          }`,
        }
      : defaultColumn;
  };

  const renderTCell = useCallback((column, row) => {
    if (column === "Date") {
      return formatDate(row[column]);
    }
    return row[column];
  }, []);

  const {
    currentPage,
    goToPageValue,
    goToPage,
    totalPages,
    handleRowsPerPageChange,
    handleGoToPageChange,
  } = usePagination({ rows, rowsPerPageValue: 5 });

  const rowsToDraw = useMemo(
    () => rows?.slice((currentPage - 1) * 5, (currentPage - 1) * 5 + 5),
    [rows, currentPage]
  );

  const handleOutlookOpen = useCallback((investorId) => {
    setIsOutlookModalOpen(true);
    setCurrentInvestorId(investorId);
  }, []);

  const getActionsCell = useCallback(
    ({ row }) => (
      <div className="d-flex">
        <Tooltip title="Add outlook">
          <IconButton size="small" aria-label="add">
            <FontAwesomeIcon
              icon={["fas", "plus"]}
              className="font-size-sm"
              onClick={() => handleOutlookOpen(row["Investor Cinchy Id"])}
            />
          </IconButton>
        </Tooltip>
      </div>
    ),
    []
  );

  return (
    <>
      {isLoading ? (
        <TSkeleton />
      ) : (
        <div
          className={`${styles.investorsTable} table-responsive-md mb-spacing-2-x2 ml-3`}
        >
          <GenericTable
            title={`Investor Coverage (${rows?.length} results)`}
            rows={rowsToDraw}
            fixedColumnHint=""
            headCells={cells?.slice(1, -1)}
            tableActions={null}
            getHeadCellStyle={null}
            getCellStyle={getCellStyle}
            renderCell={renderTCell}
            getActionsCell={getActionsCell}
            searchBar={null}
            scrollable={true}
          >
            <Pagination
              goToPage={goToPage}
              rowsPerPage={5}
              currentPage={currentPage}
              goToPageValue={goToPageValue}
              totalPages={totalPages}
              handleRowsPerPageChange={handleRowsPerPageChange}
              handleGoToPageChange={handleGoToPageChange}
            />
          </GenericTable>
          <OutlookModal
            isOpen={isOutlookModalOpen}
            companyId={currentInvestorId}
            selectedOutlook={null}
            setIsOpen={setIsOutlookModalOpen}
            investorOutlook={true}
          />
        </div>
      )}
    </>
  );
}
