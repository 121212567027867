import React from 'react';
import { useLocation } from 'react-router';

import {
    Card,
    List,
} from '@material-ui/core';

import FilterByCompany from '../../forms/filters/FilterByCompany';
import FilterByRelationshipCustomers from '../../forms/filters/FilterByRelationshipCustomers';
import FilterByRelationshipPeople from '../../forms/filters/FilterByRelationshipPeople';
import FilterByFinancings from '../../forms/filters/FilterByFinancings';

import { useFilter } from '../../hooks/useFilter';

const companyFilterPaths = [
    'sourcing/companies/company',
    'sourcing/companies/sweet-spot',
    'sourcing/companies/priority-backlog',
    'sourcing/companies/trouble-breaking-in',
    'sourcing/companies/recent-updates',
    'sourcing/companies/needs-primary-contact'
];

const financingsFilterPaths = [
    '/sourcing/financings/all',
    '/sourcing/financings/equity',
    '/sourcing/financings/m&a',
    '/sourcing/financings/out-of-business',
    '/sourcing/financings/last-week-financing',
    '/sourcing/financings/last-week-ma',
];

export default function SideBarFilters() {
    const location = useLocation();
    const { filterOptions } = useFilter({});

    const options = Object.keys(filterOptions);

   return (
        <>
            <Card className="card-box p-0 m-1 card-transparent border-0">
                <List component="div" className="nav-neutral-primary p-3">
                    {companyFilterPaths.some(item => location.pathname.includes(item)) && <FilterByCompany />}
                    {location.pathname.includes('relationships/customers') && <FilterByRelationshipCustomers options={options} />}
                    {location.pathname.includes('relationships/people') && <FilterByRelationshipPeople options={options} />}
                    {financingsFilterPaths.some(item => location.pathname.includes(item)) && <FilterByFinancings options={options} />}
                </List>
            </Card>
        </>
    );
}
