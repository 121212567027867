import React, { useState, useEffect } from "react";
import SuspenseLoading from "../SuspenseLoading/SuspenseLoading";
import { Button, makeStyles } from "@material-ui/core";
import { TableColumn } from "../../interfaces";
import ModalForm from "../ModalForm";
import ModalCheckbox from "./ModalCheckbox";

import styles from "./styles.module.scss";

const useStyles = makeStyles((theme) => ({
  saveBtn: {
    color: "#ffffff",
    fontSize: "1rem",
    padding: "0.75rem 2rem",
    backgroundColor: "#6e3cfa",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "rgb(86, 47, 204)",
    },
  },
}));

type CheckedColumn = {
  "Column Name": string;
  checked: boolean;
};

type CheckedColumns = Record<string, CheckedColumn[]>;

type ColumnsModalProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  columns: TableColumn[];
  selectedColumns: string[];
  handleSelectColumns: (selectedColumns: string[]) => void;
  detailColumns: TableColumn[];
  selectedDetailColumns: string[];
  handleDetailSelectColumns: (selectedColumns: string[]) => void;
};

const InvestorColumnsModal: React.FC<ColumnsModalProps> = ({
  isOpen,
  setIsOpen,
  columns,
  selectedColumns,
  handleSelectColumns,
  detailColumns,
  selectedDetailColumns,
  handleDetailSelectColumns,
}) => {
  const [checkedColumns, setCheckedColumns] = useState<CheckedColumns | null>(
    null
  );
  const [
    checkedDetailColumns,
    setCheckedDetailColumns,
  ] = useState<CheckedColumns | null>(null);

  const classes = useStyles();

  const initCheckedColumns = () => {
    const groupedData = columns.reduce((result: any, item) => {
      const { Category, "Column Name": columnName, ...rest } = item;
      if (!result[Category]) {
        result[Category] = [];
      }
      const lowerCaseSelectedColumns = selectedColumns?.map(col => col.toLowerCase());
      const checked = lowerCaseSelectedColumns.includes(columnName.toLowerCase());
      result[Category].push({ "Column Name": columnName, checked, ...rest });
      return result;
    }, {});

    setCheckedColumns(groupedData);

    const groupedDetailData = detailColumns?.reduce((result: any, item) => {
      const { Category, "Column Name": columnName, ...rest } = item;
      if (!result[Category]) {
        result[Category] = [];
      }
      const checked = selectedDetailColumns.includes(columnName.toLowerCase());
      result[Category].push({ "Column Name": columnName, checked, ...rest });
      return result;
    }, {});

    setCheckedDetailColumns(groupedDetailData);
  };

  const handleCheckboxChange = (category: string, columnName: string) => {
    setCheckedColumns((prevData) => {
      return {
        ...prevData,
        [category]: prevData![category].map((item) => {
          if (item["Column Name"] === columnName) {
            return { ...item, checked: !item.checked };
          }
          return item;
        }),
      };
    });
  };

  const handleDetailCheckboxChange = (category: string, columnName: string) => {
    setCheckedDetailColumns((prevData) => {
      return {
        ...prevData,
        [category]: prevData![category].map((item) => {
          if (item["Column Name"] === columnName) {
            return { ...item, checked: !item.checked };
          }
          return item;
        }),
      };
    });
  };

  const handleSave = () => {
    let onlyCheckedColumns: string[] = [];

    for (let category of Object.values(checkedColumns || {})) {
      if (Array.isArray(category) && category.length) {
        const checkedColumns = category
          .filter((item) => item.checked)
          .map((item) => item["Column Name"].toLowerCase());
        onlyCheckedColumns = [...onlyCheckedColumns, ...checkedColumns];
      }
    }

    handleSelectColumns(onlyCheckedColumns);

    let onlyCheckedDetailColumns: string[] = [];

    for (let category of Object.values(checkedDetailColumns || {})) {
      if (Array.isArray(category) && category.length) {
        const checkedDetailColumns = category
          .filter((item) => item.checked)
          .map((item) => item["Column Name"].toLowerCase());
        onlyCheckedDetailColumns = [
          ...onlyCheckedDetailColumns,
          ...checkedDetailColumns,
        ];
      }
    }

    handleDetailSelectColumns(onlyCheckedDetailColumns);
    handleClose();
  };

  const handleClose = () => {
    setCheckedColumns(null);
    setCheckedDetailColumns(null);
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      initCheckedColumns();
    }
  }, [isOpen]);

  return (
    <ModalForm
      open={isOpen}
      handleClose={handleClose}
      maxWidth={false}
      fullWidth={false}
    >
      <div className="border-0">
        <div className="card-body px-lg-5 py-lg-5">
          <div className="text-center mb-4">
            <h6 className={styles.modalTitle}>
              Select the columns that you want to appear in the table
            </h6>
          </div>

          <div className={styles.container}>
            {checkedColumns ? (
              Object.keys(checkedColumns).map((category) => (
                <div key={category} className={styles.category}>
                  <h2 className={styles.categoryTitle}>{category}</h2>
                  <ul className={styles.columnsList}>
                    {checkedColumns[category].map((item, index) => (
                      <ModalCheckbox
                        key={index}
                        label={item["Column Name"]}
                        checked={item.checked}
                        handleChange={() =>
                          handleCheckboxChange(category, item["Column Name"])
                        }
                      />
                    ))}
                  </ul>
                </div>
              ))
            ) : (
              <div className={styles.loadingSpinner}>
                <SuspenseLoading />
              </div>
            )}

            {checkedDetailColumns &&
              Object.keys(checkedDetailColumns).map((category) => (
                <div key={category} className={styles.category}>
                  <h2 className={styles.categoryTitle}>{category}</h2>
                  <ul className={styles.columnsList}>
                    {checkedDetailColumns[category]
                      .slice(0, checkedDetailColumns[category].length / 2)
                      .map((item, index) => (
                        <ModalCheckbox
                          key={index}
                          label={item["Column Name"]}
                          checked={item.checked}
                          handleChange={() =>
                            handleDetailCheckboxChange(
                              category,
                              item["Column Name"]
                            )
                          }
                        />
                      ))}
                  </ul>
                </div>
              ))}
            {checkedDetailColumns &&
              Object.keys(checkedDetailColumns).map((category) => (
                <div key={category} className={styles.category}>
                  <ul className={styles.columnsList}>
                    {checkedDetailColumns[category]
                      .slice(checkedDetailColumns[category].length / 2)
                      .map((item, index) => (
                        <ModalCheckbox
                          key={index}
                          label={item["Column Name"]}
                          checked={item.checked}
                          handleChange={() =>
                            handleDetailCheckboxChange(
                              category,
                              item["Column Name"]
                            )
                          }
                        />
                      ))}
                  </ul>
                </div>
              ))}
          </div>

          <div className="text-center">
            <Button
              variant="contained"
              className={classes.saveBtn}
              onClick={handleSave}
            >
              save
            </Button>
          </div>
        </div>
      </div>
    </ModalForm>
  );
};

export default InvestorColumnsModal;
