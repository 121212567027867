import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import clsx from 'clsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Tooltip } from '@material-ui/core';
import { Card } from './KanbanCard';
import AddOpportunityModal from './AddOpportunityModal';
import { addOpportunity, getOpportunitiesKanban, updateOpportunity } from './api';
import UniversalSearch from '../UniversalSearch';


const useStyles = makeStyles(() => ({
    root: {
        position: 'relative',
        overflowY: 'auto',
        height: '100%',
        margin: '8px',
        width: '300px',
        minHeight: '100px',
        backgroundColor: '#f6f6f6',
        borderRadius: '5px'
    },
    cardsContent: {
        position: 'absolute',
        padding: '8px',
        bottom: '8px',
        right: '4px'
    },
    title: {
        backgroundColor: 'rgba(110, 60, 250, 0.8)',
        padding: '8px',
        color: 'white',
        fontWeight: 'bold',
        fontSize: '14px'
     },
}))

const changeBoardStructure = (arr) => arr.reduce((board, item) => {
    const status = item['Opportunity Status'];
    if(status) {
        if(!board[status]) {
            board[status] = [];
        }
        board[status].push({...item, id: item['Table Cinchy Id']});
    }
    return board;
}, {})

function Column({ title, cards, onDragOver, onDrop, onDragStart, setColumns }) {
    const classes = useStyles();
    const [isAddModelOpen, setIsAddModelOpen] = useState(false);
    const [triggerLoading, setTriggerLoading] = useState(false);
    const dispatch = useDispatch();

    const refreshData = async () => {
        getOpportunitiesKanban({ dispatch }).then(res => {
            setColumns(changeBoardStructure(res));
            setTriggerLoading(false);
        })
    }

    const handleAddOpportunity = (params) => {
        setTriggerLoading(true);
        setIsAddModelOpen(false);
        addOpportunity({ dispatch, params }).then(() => refreshData())
    }

    const openAddModal = () => {
        setIsAddModelOpen(true);
    }

    return (
        <div
            onDragOver={onDragOver}
            onDrop={onDrop}
            className={classes.root}
        >
            <div className={classes.title}>
                <div>{title} ({cards.length})</div>
            </div>
            <div style={{ height: '90%', overflowY: 'auto' }}>
                {cards.map(card => (
                    <Card refreshData={refreshData} status={title} key={card.id} {...card} onDragStart={onDragStart} setColumns={setColumns} triggerLoading={triggerLoading} />
                ))}
            </div>
            <div className={classes.cardsContent}>
                    <Tooltip title="Add new opportunity" arrow placement="top">
                        <Button
                            id="blockElement"
                            className="btn-primary mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center"
                            onClick={openAddModal}
                        >
                            <FontAwesomeIcon
                                icon={['fas', 'plus']}
                                className={clsx("font-size-sm", classes.bgColor)}
                            />

                        </Button>
                    </Tooltip>
            </div>
            <AddOpportunityModal selectedStatus={title} isOpen={isAddModelOpen} onClose={() => setIsAddModelOpen(false)} onAdd={handleAddOpportunity} />
        </div>
    );
}

function Board() {
    const [columns, setColumns] = useState({});
    const dispatch = useDispatch();

    useEffect(() => {
        getOpportunitiesKanban({ dispatch }).then(res => {
            setColumns(changeBoardStructure(res))
        })
    }, [])


    const handleDragStart = (event) => {
        event.dataTransfer.setData('text', event.target.id);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

const handleDrop = async (targetColId, event) => {
    event.preventDefault();
    const cardId = Number(event.dataTransfer.getData('text/plain')); // Retrieve the ID of the dragged card
    const sourceColId = Object.keys(columns).find(key => columns[key].some(card => card.id === cardId)); // Find the original column ID

    if (sourceColId === targetColId) {
        // If the drop target is the same as the source, do nothing
        console.log("Dropped item back to its original place.");
        return;
    }

    // If the target is different, proceed with moving the card
    const card = columns[sourceColId].find(card => card.id === cardId);
    updateOpportunity({ dispatch,
        params: {
            tableCinchyId: card.id,
            status: targetColId,
        }
    })
        .then(res => {
            console.log('Log ::: res === Opportunity Status changed', res);
        })
    const newColumns = { ...columns };
    newColumns[sourceColId] = newColumns[sourceColId].filter(card => card.id !== cardId);
    newColumns[targetColId] = [...newColumns[targetColId], card];
    setColumns(newColumns);
};


    return (
        <div style={{ display: 'flex', justifyContent: 'center', height: 'calc(100vh - 180px)' }}>
            <UniversalSearch />
            {Object.entries(columns).map(([colId, cards]) => (
                <Column
                    key={colId}
                    title={colId}
                    cards={cards}
                    setColumns={setColumns}
                    onDragOver={handleDragOver}
                    onDrop={(event) => handleDrop(colId, event)}
                    onDragStart={handleDragStart}
                />
            ))}
        </div>
    );
}

export default Board;
