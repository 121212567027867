import React, { useEffect, useState, useCallback } from "react";
import { Button } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { Done, Delete } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import { updateContactDateParams } from "../../store/sourcing/sourcingThunks";
import DatePicker from "../../example-components/FormsDatepicker/FormsDatepicker1";
import styles from "./styles.module.scss";

interface CrmProps {
  data: any;
  companyId: number;
}

function formatDate(inputDate: any) {
  const quarterPattern = /^[1-4]{1}Q [1-9]{1}\d{3}$/;
  if (!quarterPattern.test(inputDate)) {
    const date = new Date(inputDate);
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();

    return inputDate ? `${month}/${day}/${year}` : "";
  }

  return inputDate;
}

const CrmOverview = ({ data, companyId }: CrmProps) => {
  const dispatch = useDispatch();
  const [crmData, setCrmData] = useState({} as any);
  const [isEditable, setIsEditable] = useState(false);
  const [dateText, setDateText] = useState("");
  const [datePreviousText, setDatePreviousText] = useState("");
  const [isUpdateDisabled, setIsUpdateDisabled] = useState(false);

  const toggleEditable = () => {
    setIsEditable(!isEditable);
  };

  const save = () => {
    setDatePreviousText(dateText);

    dispatch(
      updateContactDateParams({
        cinchyid: companyId.toString(),
        futureContactDate: formatDate(dateText),
      })
    );

    toggleEditable();
  };

  const cancel = () => {
    setDateText(datePreviousText);
    setIsUpdateDisabled(false);
    toggleEditable();
  };

  useEffect(() => {
    if (data?.length) {
      setCrmData(data?.[0]);
      setDateText(data?.[0]["Future Contact Date"]);
      setDatePreviousText(data?.[0]["Future Contact Date"]);
    }
  }, [data]);

  const handleSelectDate = (date: any) => {
    setDateText(date);
    if (date) {
      if (isNaN(date.getTime())) {
        setIsUpdateDisabled(true);
      } else {
        setIsUpdateDisabled(false);
      }
    } else {
      setIsUpdateDisabled(true);
    }
  };

  const handleDeleteContactDate = () => {
    setDateText("")
    dispatch(
      updateContactDateParams({
        cinchyid: companyId.toString(),
        futureContactDate: "",
      })
    );
  }

  return (
    <>
      <div className={styles.crmTitleContainer}>
        <p className={`${styles.crmText} font-14`}>CRM</p>
        {/* <img id="blockElement" className={styles.editButton} src="/edit.svg" alt="Edit Button" /> */}
      </div>
      <div className={styles.singleSection}>
        <div className={styles.crmFlexSection}>
          <p className={`${styles.crmSubText} font-12`}>Next Meeting Date</p>
          <div className={`${styles.crmFlexSubSection} font-12`}>
            {formatDate(crmData["Next Meeting Date"])}
          </div>
        </div>
      </div>
      <div className={styles.singleSection}>
        <div className={styles.crmFlexSection}>
          <p className={`${styles.crmSubText} font-12`}>Last Contact Date</p>
          <div className={`${styles.crmFlexSubSection} font-12`}>
            {formatDate(crmData["Last Contact Date"])}
          </div>
        </div>
      </div>
      <div className={styles.singleSection}>
        <div className={styles.crmFlexSection}>
          <p className={`${styles.crmSubText} font-12`}>Last Meeting Date</p>
          <div className={`${styles.crmFlexSubSection} font-12`}>
            {formatDate(crmData["Last Meeting Date"])}
          </div>
        </div>
      </div>
      {/* <div className={styles.singleSection}>
        <div className={styles.crmFlexSection}>
          <p className={`${styles.crmSubText} font-12`}>Sequence Status</p>
          <div className={`${styles.crmFlexSubSection} font-12`}>
            {crmData["In Sequence"] + ""}
          </div>
        </div>
      </div> */}
      <div className={styles.singleSection}>
        <div>
          <p className={`${styles.crmSubText} font-12`}>
            Last Sequence Enrolled
          </p>
          <div className={`${styles.crmSubSection} font-12`}>
            {crmData["Last Sequence Enrolled"]}
          </div>
        </div>
      </div>
      <div className={styles.singleSection}>
        <div>
          <div className={styles.headerContainer}>
            <p className={`${styles.crmSubText} font-12`}>
              Future Contact Date
            </p>
            {!isEditable && (
              <>
                <img
                  onClick={toggleEditable}
                  className={styles.editButton}
                  src="/edit.svg"
                  alt="Company Logo"
                  id="blockElement"
                />
                <Delete
                  className={styles.deleteButton}
                  onClick={() => handleDeleteContactDate()}
                ></Delete>
              </>
            )}
            {isEditable && (
              <Button
                size="small"
                id="blockElement"
                className={`${"btn-neutral-primary"} mb-2`}
                disabled={isUpdateDisabled}
                onClick={save}
              >
                <Done />
              </Button>
            )}
            {isEditable && (
              <Button
                size="small"
                id="blockElement"
                className={`${"btn-neutral-primary"} mb-2 ml-2`}
                onClick={cancel}
              >
                <Close />
              </Button>
            )}
          </div>
          <div className={`${styles.crmSubSection} font-12`}>
            {!isEditable ? (
              formatDate(dateText)
            ) : (
              <div className={styles.datePicker}>
                <DatePicker
                  handleSelectDate={(date: any) => handleSelectDate(date)}
                  selectedDate={dateText}
                  isEmpty={isUpdateDisabled}
                ></DatePicker>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CrmOverview;
