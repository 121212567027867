import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import GenericTable from "../../../../components/GenericTable";
import TSkeleton from "../../../../components/TSkeleton";

import { selectTroubleBreakingIn } from "../../../../store/dashboard/dashboardSlice";
import { getTroubleBreakingIn } from "../../../../store/dashboard/dashboardThunks";

import usePagination from "../../../../hooks/usePagination";
import Pagination from "../../../../components/Pagination/Pagination";
import styles from "./styles.module.scss";
import { formatEmptyCell } from "../../../../utils/utils";

export default function TroubleBreakingIn() {
  const dispatch = useDispatch();
  const { rows, cells, isLoading } = useSelector(selectTroubleBreakingIn);

  useEffect(() => {
    dispatch(getTroubleBreakingIn());
  }, []);

  const handleOpenCompanyDetails = (companyId: string) => {
    window.open(`/sourcing/companies/details/${companyId}`, "_blank");
  };

  const handleCompanyWebsiteSplit = useCallback((value, id) => {
    const [companyName, website] = value.split(" | ");
    return (
      <>
        <span
          className={styles.hoverBlack}
          onClick={() => handleOpenCompanyDetails(id)}
          style={{
            cursor: "pointer",
            color: "black",
            display: "block",
            fontSize: "14px",
            fontWeight: "bold",
          }}
        >
          {companyName}
        </span>
        <span style={{ cursor: "pointer", color: "black" }}>
          <a
            href={website?.startsWith("http") ? website : `http://${website}`}
            target="_blank"
            className={styles.hoverBlack}
            style={{ color: "grey", fontSize: "12px" }}
          >
            {website}
          </a>
        </span>
      </>
    );
  }, []);

  const renderTCell = useCallback((column, row) => {
    const defaultValue = formatEmptyCell(row[column]);
    switch (column) {
      case "Company Name":
        return (
          <div className={styles.cell}>
            {handleCompanyWebsiteSplit(
              formatEmptyCell(row[column]),
              row["Cinchy Id"]
            )}
          </div>
        );
      case "Investor Relationships": 
      return row[column] ? <div className={styles.tagContainer}> {row[column]?.split("|")?.map((item: any) => (
        <p className={`${styles.technologyTag} font-12`}>{item}</p>
      ))} </div> : formatEmptyCell(row[column])
      default:
        return defaultValue;
    }
  }, []);

  const {
    currentPage,
    goToPageValue,
    goToPage,
    totalPages,
    handleRowsPerPageChange,
    handleGoToPageChange,
  } = usePagination({ rows, rowsPerPageValue: 5 });

  const rowsToDraw = useMemo(
    () => rows?.slice((currentPage - 1) * 5, (currentPage - 1) * 5 + 5),
    [rows, currentPage]
  );

  return (
    <>
      {isLoading ? (
        <TSkeleton />
      ) : (
        <div className="table-responsive-md mb-spacing-2-x2 ml-3">
          <GenericTable
            title={`Trouble Breaking In (${rows?.length} results)`}
            rows={rowsToDraw}
            fixedColumnHint=""
            headCells={cells?.slice(1)}
            tableActions={null}
            getHeadCellStyle={null}
            getCellStyle={null}
            renderCell={renderTCell}
            getActionsCell={null}
            searchBar={null}
            scrollable={true}
          >
            <Pagination
              goToPage={goToPage}
              rowsPerPage={5}
              currentPage={currentPage}
              goToPageValue={goToPageValue}
              totalPages={totalPages}
              handleRowsPerPageChange={handleRowsPerPageChange}
              handleGoToPageChange={handleGoToPageChange}
            />
          </GenericTable>
        </div>
      )}
    </>
  );
}
