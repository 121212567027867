import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import Snackbar from '@material-ui/core/Snackbar';

import { QUERIES, STATUSES } from '../../constants';
import { useNotification } from '../../hooks/useNotification';
import { selectFPCIPrios } from '../../store/sourcing/sourcingSlice';
import { getData } from '../../api/sourcing';
import { customStyles } from '../../utils/coloredSelectStyles';

const updateFPCIPriority = async ({ cinchyId, fpcipriority, dispatch }) => {
    const queryParams = {
        '@cinchyid': cinchyId,
        '@fpcipriority': fpcipriority,
    };

    const queryString = new URLSearchParams(queryParams).toString();

    const { response, status: resStatus }  = await getData(`${QUERIES.COMPANY.UPDATE_FPCI_PRIORITIES}?${queryString}`, dispatch);
    if(resStatus === STATUSES.SUCCESS) {
        return response.payload;
    }
}

export const SingleSelect = ({
    options = {},
    name,
    defaultValue,
    onChange,
}) => {

    const [selectedOption, setSelectedOption] = useState(defaultValue || { label: '', value: '' });

    const handleChange = (option) => {
        onChange(option.value);
        setSelectedOption(option)
    }

    return (
        <div style={{ margin: '5px 0', width: '100%' }}>
            <Select
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 4,
                    colors: {
                        ...theme.colors,
                        primary25: 'rgba(110, 60, 250, 0.05)',
                        primary: '#6e3cfa',
                    },
                })}
                styles={customStyles}
                name={name}
                // @ts-ignore
                options={Object.values(options)}
                isMulti={false}
                isSearchable
                value={selectedOption}
                onChange={handleChange}
            />
        </div>
    );
};


const FPCIPriorities = ({ cinchyId, defaultValue, name }) => {
    const [selectedStatus, setSelectedStatus] = useState('');
    const [options, setOptions] = useState([]);

    const statuses = useSelector(selectFPCIPrios);
    const dispatch = useDispatch();

    useEffect(() => {
        if(statuses?.length) {
            // @ts-ignore
            setOptions(statuses.map(item => ({ value: item.Status, label: item.Status, ...item })))
        }
    }, [statuses])



    const  { notificationData, openNotification } = useNotification();
    const {  open, message, toastrStyle, vertical, horizontal } = notificationData;

    useEffect(() => {
        if(selectedStatus) {
            updateFPCIPriority({ cinchyId: cinchyId, fpcipriority: selectedStatus, dispatch }).then(() => {
                openNotification({
                    open: true,
                    message: 'Status Successfully updated',
                });
            })
        }
    }, [selectedStatus, cinchyId]);


    return (
        <>
            <SingleSelect onChange={setSelectedStatus} name={name} options={options} defaultValue={defaultValue} />
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                classes={{ root: toastrStyle }}
                open={open}
                message={message}
            />
        </>
    )
}

export default FPCIPriorities;