import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { ListItem } from "@material-ui/core";
import moment from "moment";
import { IOutlook } from "../../utils/dataGrouping";
import {
  getCompanyOutlook,
  deleteCompanyOutlook,
} from "../../store/sourcing/sourcingThunks";
import * as NotificationHelper from "../../helpers/notification";
import { selectLoading } from "../../store/sourcing/sourcingSlice";

import styles from "./styles.module.scss";
import { Delete } from "@material-ui/icons";

type OutlookProps = {
  outlook: IOutlook;
  openOutlookModal: () => void;
};

const Outlook: React.FC<OutlookProps> = ({ outlook, openOutlookModal }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectLoading);

  const handleDeleteOutlook = async () => {
    await dispatch(deleteCompanyOutlook(outlook["Table Cinchy Id"]));
    dispatch(getCompanyOutlook(outlook["Company Cinchy Id"]));
    NotificationHelper.show("Company outlook successfully deleted", "success");
  };

  return (
    <>
      <div className={styles.internalNotesCardContainer}>
        <div className={styles.singleSection}>
          <div className={styles.internalNotesTextSection}>
            <div>
              <div className="text-black opacity-5 font-12">
                {moment(outlook.Date).format("MM/DD/YYYY")}
              </div>
              <div className="text-black font-12">{outlook.Author}</div>
              <span className="d-block font-12">{outlook.Outlook}</span>
            </div>
            <div className={styles.actionsContainer}>
              <div className="text-right">
                <img
                  onClick={openOutlookModal}
                  className={styles.buttonClickable}
                  src="/edit.svg"
                  alt="Company Logo"
                  id="blockElement"
                />

                <Delete
                  className={styles.buttonClickable}
                  onClick={handleDeleteOutlook}
                ></Delete>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Outlook;
