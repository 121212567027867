import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import Select from 'react-select';

import { getData } from '../../api/sourcing';
import { QUERIES, STATUSES } from '../../constants';
import { useNotification } from '../../hooks/useNotification';
import { selectStatuses } from '../../store/sourcing/sourcingSlice';
import { ISingleSelect } from '../SingleSelect/SIngleSelect';
import { customStyles} from '../../utils/coloredSelectStyles';

type TStatusSelect = {
    name: string;
    cinchyId: string;
    options?: Record<string, any>;
    defaultValue: { label: string, value: string };
    // onChange: Dispatch<SetStateAction<string>> | ((value: string | number | null) => void)
}

const updateStatus = async ({ affinityorgid, status }: { affinityorgid: string, status: string }) => {
    const queryParams = {
        '@cinchyid': affinityorgid,
        '@status': status,
    };

    const queryString = new URLSearchParams(queryParams).toString();

    const { response, status: resStatus }  = await getData(`${QUERIES.COMPANY_DETAILS.UPDATE_STATUS}?${queryString}`);
    if(resStatus === STATUSES.SUCCESS) {
        // @ts-ignore
        return response.payload;
    }
}

export const SingleSelect: React.FC<ISingleSelect> = ({
  options = {},
  name,
  defaultValue,
  onChange,
}) => {

    const [selectedOption, setSelectedOption] = useState(defaultValue || { label: '', value: '' });

    const handleChange = (option: any) => {
        console.log('selectedRowsIds', option);
        onChange(option.value);
        setSelectedOption(option)
    }

    return (
        <div style={{ margin: '5px 0', width: '100%' }}>
            <Select
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 4,
                    colors: {
                        ...theme.colors,
                        primary25: 'rgba(110, 60, 250, 0.05)',
                        primary: '#6e3cfa',
                    },
                })}
                styles={customStyles}
                name={name}
                // @ts-ignore
                options={Object.values(options)}
                isMulti={false}
                isSearchable
                value={selectedOption}
                onChange={handleChange}
            />
        </div>
    );
};


const StatuesSelect = ({ cinchyId, defaultValue, name } : TStatusSelect) => {
    const [selectedStatus, setSelectedStatus] = useState<string>('');
    const [options, setOptions] = useState([]);

    const statuses = useSelector(selectStatuses);

    useEffect(() => {
        if(statuses?.length) {
            // @ts-ignore
            setOptions(statuses.map(item => ({ value: item.Status, label: item.Status, ...item })))
        }
    }, [statuses])



    const  { notificationData, openNotification } = useNotification();
    const {  open, message, toastrStyle, vertical, horizontal } = notificationData;

    useEffect(() => {
        if(selectedStatus) {
            updateStatus({ affinityorgid: cinchyId, status: selectedStatus }).then(() => {
                openNotification({
                    open: true,
                    message: 'Status Successfully updated',
                });
            })
        }
    }, [selectedStatus, cinchyId]);


    return (
        <>
            {/*// @ts-ignore*/}
            <SingleSelect onChange={setSelectedStatus} name={name} options={options} defaultValue={defaultValue} />
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                classes={{ root: toastrStyle }}
                open={open}
                message={message}
            />
        </>
    )
}

export default StatuesSelect;