import React from 'react';
import { StatusSelect } from '../SingleSelect/SIngleSelect';

const OPPORTUNITY_OPTIONS = [
    { label: 'Pass', value: 'Pass' },
    { label: 'Coming to Market', value: 'Coming to Market' },
    { label: 'Tracking', value: 'Tracking' },
    { label: 'Term Sheet / 10-Pager', value: 'Term Sheet / 10-Pager' },
    { label: 'Closing / Final Presentation', value: 'Closing / Final Presentation' },
    { label: 'Diligence / 1-Pager', value: 'Diligence / 1-Pager' },
]

function OpportunityStatusSelect({ setName, selectedStatus }) {
    return (
        <>
            <StatusSelect name='Opportunity Status' defaultValue={{ label: selectedStatus, value: selectedStatus }} options={OPPORTUNITY_OPTIONS} onChange={setName} />
        </>
    );
}

export default OpportunityStatusSelect;