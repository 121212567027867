import React from "react";
import { Button } from "@material-ui/core";
import MetricIndicator from "../MetricIndicator";
import styles from '../styles.module.scss';

interface HeaderKeyMetrics {
    green: string;
    red: string; 
}

interface HeaderSectionProps {
    headerKeyMetrics: HeaderKeyMetrics;
    setIsBenchmarksOpen: (isOpen: boolean) => void;
}

const HeaderSection: React.FC<HeaderSectionProps> = ({ headerKeyMetrics, setIsBenchmarksOpen }) => {
    return (
        <section className={styles.headerSection}>
            <span className={`${styles.title} ${styles.headerSectionElement} font-14 font-weight-bold`}>Key Metrics</span>
            
            <div className={`${styles.metricGroup} ${styles.headerSectionElement} font-12`}>
                <MetricIndicator
                    color="red"
                    label={headerKeyMetrics.red}
                />
                <span>/</span>
                <MetricIndicator
                    color="green"
                    label={headerKeyMetrics.green}
                />
            </div>
            <Button
                size="small"
                className={`btn-second mr-2 ${styles.headerSectionElement}`}
                onClick={() => setIsBenchmarksOpen(true)}
                id="blockElement"
            >
                <span className="btn-wrapper--label p-1 font-12">View Benchmarks</span>
            </Button>
        </section>
    );
};

export default HeaderSection;
