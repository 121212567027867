import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { fakeApiRequest, getData, getCompaniesData, getAffinityData } from '../../api/sourcing';
import hubspotLists from '../../mockData/soursing.hubspot.json';
import {
    API_COMPANIES_URL,
    CONTACT_INFO,
    CRM_CH,
    DEMOGRAPHICS,
    FUNDRAISING_HISTORY_1,
    FUNDRAISING_HISTORY_1_NEW_COLUMNS,
    FUNDRAISING_HISTORY_2,
    FUNDRAISING_HISTORY_2_NEW_COLUMNS,
    INTERNAL_NOTES,
    INTERNAL_NOTES_NEW_COLUMNS,
    INVESTORS,
    KEY_METRICS,
    OWNERS_INFO,
    QUERIES,
    RELATIONSHIPS,
    STATUSES,
    SUMMARY,
    TAXONOMY,
} from '../../constants';
import {
    changeBoardColumnNames,
    changeDataShape,
    changeFinancialsColumnNames,
    selectDataByColumns,
    selectNecessaryColumns
} from '../../utils/dataGrouping';
import { TWhitespaceColumn, whitespaceGroupingByRow, getColumns } from '../../utils/TGroping';
import savedSearch from '../../mockData/sourcing.save_search.json';
import {
    AddPrimaryCompetitorsParams,
    AddKeyCustomersParams,
    CompanyOutlookParams,
    UpdateInternalNotesParams,
    TagOptionPayload,
    CompanyResearch,
    TaggingResearchByTechnologyPayload,
    CreatedResearch,
    TaggingResearchByCompanyPayload,
    InsertCompanyKeyWordParams,
    UpdateCompanyKeyWordParams,
    AddArrParams,
    UpdateKeyCustomersParams,
    InvestorOutlookParams
} from '../../interfaces';
import { getAuthHeader } from '../../helpers/auth';


export const getCompaniesByCompany = createAsyncThunk(
    "sourcing/getCompaniesByCompany",
    async (activeTab: string | undefined, { dispatch }) => {
        // @ts-ignore
        const API_PATH =  QUERIES.COMPANY[activeTab || 'BY_COMPANY'];
        const { response, status } = await getData(API_PATH, dispatch);
        const { response: editColumns, status: columnsStatus } = await getData(QUERIES.COMPANY.EDIT_COLUMNS, dispatch);
        if(status === STATUSES.SUCCESS && columnsStatus === STATUSES.SUCCESS) {
            const rows = whitespaceGroupingByRow(response);
            const withCompanyName = { ...editColumns, data:  [...editColumns.data] }
            const cells = whitespaceGroupingByRow(withCompanyName);
            return {
                rows,
                cells,
                activeTab: activeTab,
            };
        }
    }
);

export const getCompanyBoardData = createAsyncThunk(
    "sourcing/getCompanyBoardData",
    async (companyId: number, { dispatch }) => {
        const { response, status } = await getData(`${QUERIES.COMPANY_DETAILS.BOARD_MEMBER}?@cinchyid=${companyId}`, dispatch);
        if(status === STATUSES.SUCCESS) {
            const result = whitespaceGroupingByRow(response)
            const filteredData = changeBoardColumnNames(result)
            return filteredData;
        }
    }
);
export const getCompanyFinancialsData = createAsyncThunk(
    "sourcing/getCompanyFinancialsData",
    async (companyId: number, { dispatch }) => {
        const { response, status } = await getData(`${QUERIES.COMPANY_DETAILS.FINANCIALS}?@cinchyid=${companyId}`, dispatch);
        if(status === STATUSES.SUCCESS) {
            const result = whitespaceGroupingByRow(response)
            const columns = getColumns(response.schema)

            return {data : result, columns: columns};
        }
    }
);

export const getCompanyPrimaryResearch = createAsyncThunk(
    "sourcing/getCompanyPrimaryResearch",
    async (companyId: number, { dispatch }) => {
        const { response, status } = await getData(`${QUERIES.COMPANY_DETAILS.PRIMARY_RESEARCH}?@cinchyid=${companyId}`, dispatch);
        if(status === STATUSES.SUCCESS) {
            const result = whitespaceGroupingByRow(response)
            return result;
        }
    }
);

export const getCompanyCustomers = createAsyncThunk(
    "sourcing/getCompanyCustomers",
    async (companyId: number, { dispatch }) => {
        const { response, status } = await getData(`${QUERIES.COMPANY_DETAILS.KEY_CUSTOMERS.GET}?%40cinchyid=${companyId}`, dispatch);
        if(status === STATUSES.SUCCESS) {
            const result = whitespaceGroupingByRow(response);
            const columns = getColumns(response.schema)
            return {data : result, columns: columns};
        }
    }
);

export const addCompanyCustomer = createAsyncThunk(
    "sourcing/addCompanyCustomer",
    async ({
        data, companyId
    }: AddKeyCustomersParams, { dispatch }) => {
        const queryParams = {
            '@Company': String(companyId),
            '@customer': data['Customer Name'],
            '@arr': data['ARR'],
            '@asofdate': data['As Of Date'],
            '@championtitle': data['Champion Title'],
            '@competitors': data['Competitors if Competitive Process'],
            '@customerlinkedinurl': data['Customer LinkedIn URL'],
            '@replaced': data['Replaced if Rip and Replace'],
            '@usecase': data['Use Case'],
            '@website': '',
        };

        const queryString = new URLSearchParams(queryParams).toString();
        const url = `${QUERIES.COMPANY_DETAILS.KEY_CUSTOMERS.ADD}?${queryString}`;

        const { response, status } = await getData(url, dispatch);
        if(status === STATUSES.SUCCESS) {
            return response;
        }
    }
);

export const updateCompanyCustomer = createAsyncThunk(
    "sourcing/updateCompanyCustomer",
    async ({data}: UpdateKeyCustomersParams, { dispatch }) => {
        const queryParams = {
            '@cinchyid': data['Table Cinchy Id'],
            '@customername': data['Customer Name'],
            '@arr': data['ARR'],
            '@asofdate': data['As Of Date'],
            '@champion': data['Champion Title'],
            '@competitors': data['Competitors if Competitive Process'],
            '@linkedinid': data['Customer LinkedIn URL'],
            '@replaced': data['Replaced if Rip and Replace'],
            '@usecase': data['Use Case'],
        };

        const queryString = new URLSearchParams(queryParams).toString();
        const url = `${QUERIES.COMPANY_DETAILS.KEY_CUSTOMERS.UPDATE}?${queryString}`;

        const { response, status } = await getData(url, dispatch);
        if(status === STATUSES.SUCCESS) {
            return response;
        }
    }
);

export const deleteCompanyCustomer = createAsyncThunk(
    "sourcing/updateCompanyCustomer",
    async (tableRowId: number, { dispatch }) => {
        const url = `${QUERIES.COMPANY_DETAILS.KEY_CUSTOMERS.DELETE}?%40cinchyid=${tableRowId}`;

        const { response, status } = await getData(url, dispatch);
        if(status === STATUSES.SUCCESS) {
            return response;
        }
    }
);

interface UpdateCompanyNameParams {
    cinchyId: number | string
    companyName: string
}

export const updateCompanyName = createAsyncThunk(
    "sourcing/updateCompanyName",
    async ({cinchyId, companyName} : UpdateCompanyNameParams, { dispatch }) => {
        const url = `${QUERIES.COMPANY.EDIT_COMPANY_NAME}?%40cinchyid=${cinchyId}&%40name=${companyName}`;

        const { response, status } = await getData(url, dispatch);
        if(status === STATUSES.SUCCESS) {
            return response;
        }
    }
);

interface UpdateNoteParams {
    cinchyId: number | string
    note: string
}

export const updateNote = createAsyncThunk(
    "sourcing/updateNote",
    async ({cinchyId, note} : UpdateNoteParams, { dispatch }) => {
        const url = `${QUERIES.COMPANY.EDIT_NOTE}?%40cinchyid=${cinchyId}&%40notes=${note}`;

        const { response, status } = await getData(url, dispatch);
        if(status === STATUSES.SUCCESS) {
            return response;
        }
    }
);

export const deleteOpportunity = createAsyncThunk(
    "sourcing/deleteOpportunity",
    async (tableRowId: number, { dispatch }) => {
        const url = `${QUERIES.OPPORTUNITIES.DELETE}?%40tablecinchyid=${tableRowId}`;

        const { response, status } = await getData(url, dispatch);
        if(status === STATUSES.SUCCESS) {
            return response;
        }
    }
);

export const getSingleEntryDetails = createAsyncThunk(
    "sourcing/getSingleEntryDetails",
    async (companyId: number, { dispatch }) => {
        const { response, status }  = await getData(`${QUERIES.COMPANY_DETAILS.SINGLE_ENTRY}?@cinchyid=${companyId}`, dispatch);
        if(status === STATUSES.SUCCESS) {
            const result = whitespaceGroupingByRow(response);
            const internalNotes = selectDataByColumns(result, INTERNAL_NOTES, INTERNAL_NOTES_NEW_COLUMNS);
            const keyMetrics = selectDataByColumns(result, KEY_METRICS);
            const fundraisingHistory1 = selectDataByColumns(
                result,
                FUNDRAISING_HISTORY_1,
                FUNDRAISING_HISTORY_1_NEW_COLUMNS
            );
            const fundraisingHistory2 = selectDataByColumns(
                result,
                FUNDRAISING_HISTORY_2,
                FUNDRAISING_HISTORY_2_NEW_COLUMNS
            );
            const investorsPeople = selectDataByColumns(result, INVESTORS);
            const relationships = selectDataByColumns(result, RELATIONSHIPS);
            const summary = selectDataByColumns(result, SUMMARY);
            const taxonomy = selectDataByColumns(result, TAXONOMY);
            const crm = selectDataByColumns(result, CRM_CH);
            const demographics = selectDataByColumns(result, DEMOGRAPHICS);
            const contactInfo = selectDataByColumns(result, CONTACT_INFO);
            const ownersInfo = selectDataByColumns(result, OWNERS_INFO);

            let finalData = {
                internalNotes,
                keyMetrics,
                fundraisingHistory1,
                fundraisingHistory2,
                investorsPeople,
                relationships,
                summary,
                taxonomy,
                crm,
                demographics,
                contactInfo,
                ownersInfo,
                // @ts-ignore
                hubspotSequence: result?.[0]?.['Hubspot Sequence'],
                // @ts-ignore
                companyName: result?.[0]?.['Company Name']
            };

            return finalData;
        }
    }
);

export const getPrimaryCompetitorsByCompany = createAsyncThunk(
    "sourcing/getPrimaryCompetitorsByCompany",
    async (companyId: number, { dispatch }) => {
        const { response, status } = await getData(`${QUERIES.PRIMARY_COMPETITORS.GET_SINGLE}?@companycinchyid=${companyId}`, dispatch);
        if(status === STATUSES.SUCCESS) {
            const result = whitespaceGroupingByRow(response);
            return result;
        }
    }
);

export const getSecondaryCompetitors = createAsyncThunk(
    "sourcing/getSecondaryCompetitors",
    async (companyId: number, { dispatch }) => {
        const { response, status } = await getData(`${QUERIES.COMPANY_DETAILS.SECONDARY_COMPETITORS_GET}?@companycinchyid=${companyId}`, dispatch);
        if(status === STATUSES.SUCCESS) {
            const result = whitespaceGroupingByRow(response);
            return result;
        }
    }
);

export const getCompaniesColumnWidths = createAsyncThunk(
    "sourcing/getCompaniesColumnWidths",
    async (_, { dispatch }) => {
        const { response, status } = await getData(QUERIES.COMPANY.COLUMN_WIDTHS, dispatch);
        if(status === STATUSES.SUCCESS) {
            const result = changeDataShape(response);
            return result
        }
    }
);

export const getFinancingColumnWidths = createAsyncThunk(
    "sourcing/getFinancingColumnWidths",
    async (_, { dispatch }) => {
        const { response, status } = await getData(QUERIES.FINANCING.COLUMN_WIDTHS, dispatch);
        if(status === STATUSES.SUCCESS) {
            const result = changeDataShape(response);
            return result
        }
    }
);

export const getCompaniesByInvestorColumnWidths = createAsyncThunk(
    "sourcing/getCompaniesByInvestorColumnWidths",
    async (_, { dispatch }) => {
        const { response, status } = await getData(QUERIES.COMPANY.INVESTOR_COLUMN_WIDTHS, dispatch);
        if(status === STATUSES.SUCCESS) {
            const result = changeDataShape(response);
            return result
        }
    }
);

export const getCompaniesFinancingColumnWidths = createAsyncThunk(
    "sourcing/getCompaniesFinancingColumnWidths",
    async (_, { dispatch }) => {
        const { response, status } = await getData(QUERIES.COMPANY_DETAILS.FUNDRAISING.COLUMN_WIDTHS, dispatch);
        if(status === STATUSES.SUCCESS) {
            const result = changeDataShape(response);
            return result
        }
    }
);

export const getKeyCustomersColumnWidths = createAsyncThunk(
    "sourcing/getKeyCustomersColumnWidths",
    async (_, { dispatch }) => {
        const { response, status } = await getData(QUERIES.COMPANY_DETAILS.KEY_CUSTOMERS.COLUMN_WIDTHS, dispatch);
        if(status === STATUSES.SUCCESS) {
            const result = changeDataShape(response);
            return result
        }
    }
);

export const getFinancialsColumnWidths = createAsyncThunk(
    "sourcing/getFinancialsColumnWidths",
    async (_, { dispatch }) => {
        const { response, status } = await getData(QUERIES.COMPANY_DETAILS.FINANCIALS_COLUMN_WIDTHS, dispatch);
        if(status === STATUSES.SUCCESS) {
            const result = changeDataShape(response);
            return result
        }
    }
);

export const getFlagsColumnWidths = createAsyncThunk(
    "sourcing/getFlagsColumnWidths",
    async (_, { dispatch }) => {
        const { response, status } = await getData(QUERIES.FLAGS.COLUMN_WIDTHS, dispatch);
        if(status === STATUSES.SUCCESS) {
            const result = changeDataShape(response);
            return result
        }
    }
);

export const getFundraisingColumnWidths = createAsyncThunk(
    "sourcing/getFundraisingColumnWidths",
    async (_, { dispatch }) => {
        const { response, status } = await getData(QUERIES.COMPANY_DETAILS.FUNDRAISING_COLUMN_WIDTHS, dispatch);
        if(status === STATUSES.SUCCESS) {
            const result = changeDataShape(response);
            return result
        }
    }
);

interface CompaniesByInvestorParams {
    columns: string[]
    activeTab: string | undefined
}

export const getCompaniesByInvestor = createAsyncThunk(
    "sourcing/getCompaniesByInvestor",
    async ({columns, activeTab} : CompaniesByInvestorParams, { dispatch }) => {
        // @ts-ignore
        const API_PATH = QUERIES.COMPANY[activeTab || 'BY_INVESTOR'];
        const { response, status } = await getData(API_PATH, dispatch);
        if(status === STATUSES.SUCCESS) {
            const result = changeDataShape(response);
            const filteredData = selectNecessaryColumns(result, columns);
            return filteredData;
        }
    }
);

interface CompaniesByInvestorDetailParams {
    investorId: string
    columns: string[]
    activeTab: string[]
}

export const getCompaniesByInvestorDetail = createAsyncThunk(
    "sourcing/getCompaniesByInvestorDetail",
    async ({investorId, columns, activeTab} : CompaniesByInvestorDetailParams, { dispatch }) => {
        console.log(activeTab)
        // @ts-ignore
        const API_PATH = QUERIES.COMPANY[activeTab || 'BY_INVESTOR_DETAIL'];
        const { response, status }  = await getData(`${API_PATH}?%40investorcinchyid=${investorId}`, dispatch);
        if(status === STATUSES.SUCCESS) {
            const result = changeDataShape(response);
            const filteredData = selectNecessaryColumns(result, columns);
            return filteredData;
        }
    }
);

export const getHubspotLists = createAsyncThunk(
    "sourcing/getHubspotLists",
    async (_, { dispatch }) => {
        const { response, status } = await fakeApiRequest(hubspotLists);
        if(status === STATUSES.SUCCESS) {

        }
        return response;
    }
);
export const getSavedSearch = createAsyncThunk(
    "sourcing/getSavedSearch",
    async (_, { dispatch }) => {
        const { response, status } = await fakeApiRequest(savedSearch);
        if(status === STATUSES.SUCCESS) {

        }
        return response.data;
    }
);

export const getHeadcountHistory = createAsyncThunk(
    "sourcing/getHeadcountHistory",
    async (companyId: number, { dispatch }) => {
        const { response, status }  = await getData(`${QUERIES.COMPANY_DETAILS.HEADCOUNT_HISTORY}?%40cinchyId=${companyId}`, dispatch);
        if(status === STATUSES.SUCCESS) {
            const result = whitespaceGroupingByRow(response);
            return result
        }
    }
);

export const getPrimaryContacts = createAsyncThunk(
    "sourcing/getPrimaryContacts",
    async (companyId: number, { dispatch }) => {
        const { response, status }  = await getData(`${QUERIES.COMPANY_DETAILS.CONTACT_GET}?%40companycinchyid=${companyId}`, dispatch);
        if(status === STATUSES.SUCCESS) {
            const result = whitespaceGroupingByRow(response);
            return result
        }
    }
);

export const getTopicInsights = createAsyncThunk(
    "sourcing/getTopicInsights",
    async (keyWord: String, { dispatch }) => {
        const { response, status }  = await getData(`${QUERIES.TOPIC_INSIGHTS.SELECT}?%40searchWord=${keyWord}`, dispatch);
        if(status === STATUSES.SUCCESS) {
            const result = whitespaceGroupingByRow(response);
            return result;
        }
    }
);

export const getTopicInsightsTracking = createAsyncThunk(
    "sourcing/getTopicInsightsTracking",
    async (keyWord: String, { dispatch }) => {
        const { response, status }  = await getData(`${QUERIES.TOPIC_INSIGHTS.TRACKING}?%40searchWord=${keyWord}`, dispatch);
        if(status === STATUSES.SUCCESS) {
            const result = changeDataShape(response);
            return result;
        }
    }
);

export const getTopicInsightsFunding = createAsyncThunk(
    "sourcing/getTopicInsightsFunding",
    async (keyWord: String, { dispatch }) => {
        const { response, status }  = await getData(`${QUERIES.TOPIC_INSIGHTS.FUNDED}?%40searchWord=${keyWord}`, dispatch);
        if(status === STATUSES.SUCCESS) {
            const result = changeDataShape(response);
            return result;
        }
    }
);

export const getCompanyOutlook = createAsyncThunk(
    "sourcing/getCompanyOutlook",
    async (companyId: number, { dispatch }) => {
        const { response, status }  = await getData(`${QUERIES.COMPANY_DETAILS.COMPANY_OUTLOOK.GET}?%40companycinchyid=${companyId}`, dispatch);
        if(status === STATUSES.SUCCESS) {
            const result = whitespaceGroupingByRow(response);
            return result;
        }
    }
);

export const getRecentOverview = createAsyncThunk(
    "sourcing/getRecentOverview",
    async (companyId: number, { dispatch }) => {
        const { response, status }  = await getData(`${QUERIES.COMPANY_DETAILS.RECENT_OVERVIEW}?%40companycinchyid=${companyId}`, dispatch);
        if(status === STATUSES.SUCCESS) {
            const result = whitespaceGroupingByRow(response);
            return result;
        }
    }
);

export const getCompanyContacts = createAsyncThunk(
    "sourcing/getCompanyContacts",
    async (companyId: number, { dispatch }) => {
        const { response, status }  = await getData(`${QUERIES.COMPANY_DETAILS.COMPANY_CONTACTS.GET}?%40companycinchyid=${companyId}`, dispatch);
        if(status === STATUSES.SUCCESS) {
            const result = whitespaceGroupingByRow(response);
            const columns = getColumns(response.schema)
            return {data : result, columns: columns};
        }
    }
);

export const getFinancings = createAsyncThunk(
    "sourcing/getFinancings",
    async (companyId: number, { dispatch }) => {
        const { response, status }  = await getData(`${QUERIES.COMPANY_DETAILS.FUNDRAISING.SELECT}?%40companycinchyid=${companyId}`, dispatch);
        if(status === STATUSES.SUCCESS) {
            const result = whitespaceGroupingByRow(response);

            const columns = getColumns(response.schema)
            return {data : result, columns: columns};
        }
    }
);

export const getSingleFundraising = createAsyncThunk(
    "sourcing/getSingleFundraising",
    async (companyId: number, { dispatch }) => {
        const { response, status }  = await getData(`${QUERIES.COMPANY_DETAILS.FUNDRAISING.SINGLE_SELECT}?%40companycinchyid=${companyId}`, dispatch);
        if(status === STATUSES.SUCCESS) {
            const result = whitespaceGroupingByRow(response);
            const fundraisingHistory1 = selectDataByColumns(
                result,
                FUNDRAISING_HISTORY_1,
                FUNDRAISING_HISTORY_1_NEW_COLUMNS
            );
            const fundraisingHistory2 = selectDataByColumns(
                result,
                FUNDRAISING_HISTORY_2,
                FUNDRAISING_HISTORY_2_NEW_COLUMNS
            );
            return {fundraisingHistory1, fundraisingHistory2};
        }
    }
);

export const getSingleFundraisingPartOne = createAsyncThunk(
    "sourcing/getSingleFundraisingPartOne",
    async (companyId: number, { dispatch }) => {
        const { response, status }  = await getData(`${QUERIES.COMPANY_DETAILS.FUNDRAISING.SELECT_ONE}?%40cinchyid=${companyId}`, dispatch);
        if(status === STATUSES.SUCCESS) {
            const result = whitespaceGroupingByRow(response)
            const columns = getColumns(response.schema)
            return {data : result, columns: columns};
        }
    }
);

export const getSingleFundraisingPartTwo = createAsyncThunk(
    "sourcing/getSingleFundraisingPartTwo",
    async (companyId: number, { dispatch }) => {
        const { response, status }  = await getData(`${QUERIES.COMPANY_DETAILS.FUNDRAISING.SELECT_TWO}?%40cinchyid=${companyId}`, dispatch);
        if(status === STATUSES.SUCCESS) {
            const result = whitespaceGroupingByRow(response)
            const columns = getColumns(response.schema)
            return {data : result, columns: columns};
        } else {
            throw new Error(`Request failed: sourcing/getSingleFundraisingPartTwo`);
        }
    }
);

export const getOwnersList = createAsyncThunk(
    "sourcing/getOwnersList",
    async (_, { dispatch }) => {
        const { response, status }  = await getData(QUERIES.OWNERS.SELECT, dispatch);
        if(status === STATUSES.SUCCESS) {
            const result = whitespaceGroupingByRow(response);
            return result;
        }
    }
);

export const getMissPassLossList = createAsyncThunk(
    "sourcing/getMissPassLossList",
    async (_, { dispatch }) => {
        const { response, status }  = await getData(QUERIES.FINANCING.MISS_PASS_LOSS, dispatch);
        if(status === STATUSES.SUCCESS) {
            const result = whitespaceGroupingByRow(response);
            return result;
        }
    }
);

export const getStatuses = createAsyncThunk(
    "sourcing/getStatuses",
    async (_, { dispatch }) => {
        const { response, status }  = await getData(QUERIES.STATUSES.SELECT, dispatch);
        if(status === STATUSES.SUCCESS) {
            const result = whitespaceGroupingByRow(response);
            return result;
        }
    }
);

export const getFPCIPriorities = createAsyncThunk(
    "sourcing/getFPCIPriorities",
    async (_, { dispatch }) => {
        const { response, status }  = await getData(QUERIES.COMPANY.FPCI_PRIORITIES, dispatch);
        if(status === STATUSES.SUCCESS) {
            return response;
        }
    }
);

export const getFPCIEngagement = createAsyncThunk(
    "sourcing/getFPCIEngagement",
    async (_, { dispatch }) => {
        const { response, status }  = await getData(QUERIES.COMPANY.FPCI_ENGAGEMENT, dispatch);
        if(status === STATUSES.SUCCESS) {
            return response;
        }
    }
);

export const getSectorsList = createAsyncThunk(
    "sourcing/getSectorsList",
    async (_, { dispatch }) => {
        const { response, status }  = await getData(QUERIES.SECTORS.SELECT, dispatch);
        if(status === STATUSES.SUCCESS) {
            const result = whitespaceGroupingByRow(response);
            return result;
        }
    }
);

export const getPriorityList = createAsyncThunk(
    "sourcing/getPriorityList",
    async (_, { dispatch }) => {
        const { response, status }  = await getData(QUERIES.PRIORITY_LIST.SELECT, dispatch);
        if(status === STATUSES.SUCCESS) {
            const result = whitespaceGroupingByRow(response);
            return result;
        }
    }
);

export const getRelationshipList = createAsyncThunk(
    "sourcing/getRelationshipList",
    async (_, { dispatch }) => {
        const { response, status }  = await getData(QUERIES.RELATIONSHIP_LIST.SELECT, dispatch);
        if(status === STATUSES.SUCCESS) {
            const result = whitespaceGroupingByRow(response);
            return result;
        }
    }
);

export const getPipelineStatusList = createAsyncThunk(
    "sourcing/getPipelineStatusList",
    async (_, { dispatch }) => {
        const { response, status }  = await getData(QUERIES.PIPELINE_STATUS.SELECT, dispatch);
        if(status === STATUSES.SUCCESS) {
            const result = whitespaceGroupingByRow(response);
            return result;
        }
    }
);

export const getPrimaryCompetitorsList = createAsyncThunk(
    'sourcing/getPrimaryCompetitorsList',
    async (_, { dispatch }) => {
        const url = QUERIES.PRIMARY_COMPETITORS.GET_LIST;
        const { response, status }  = await getData(url, dispatch);

        if (status === STATUSES.SUCCESS) {
            return whitespaceGroupingByRow(response);
        }
    }
);

export const addPrimaryCompetitors = createAsyncThunk(
    'sourcing/insertPrimaryCompetitors',
    async ({ companyId, competitors }: AddPrimaryCompetitorsParams, { dispatch }) => {

        const queryParams = {
            '@companyid': String(companyId),
            '@primarycompetitors': competitors,
        };

        const queryString = new URLSearchParams(queryParams).toString();
        const url = `${QUERIES.COMPANY_DETAILS.PRIMARY_COMPETITORS.ADD}?${queryString}`;
        const { response, status }  = await getData(url, dispatch);
        if (status === STATUSES.SUCCESS) {
            return response;
        }
    }
);

export const insertArr = createAsyncThunk(
    'sourcing/insertArr',
    async ({ companyId, date, arr }: AddArrParams, { dispatch }) => {

        const queryParams = {
            '@Company': String(companyId),
            '@arr': arr,
            '@date': date,
        };

        const queryString = new URLSearchParams(queryParams).toString();
        const url = `${QUERIES.COMPANY_DETAILS.ARR.INSERT}?${queryString}`;
        const { response, status }  = await getData(url, dispatch);
        if (status === STATUSES.SUCCESS) {
            return response;
        }
    }
);

interface AddFinancialsParams {
    data: {
        ARR: string;
        Cash: string;
        'Customer Count': string;
        Date: string;
        'Monthly Burn': string;
    },
    companyId: string
}

export const addFinancials = createAsyncThunk(
    'sourcing/insertFinancials',
    async ({ data, companyId }: AddFinancialsParams, { dispatch }) => {
        try {
            const queryParams = {
                '@arr': data['ARR'],
                '@cash': data['Cash'],
                '@cashburned': data['Monthly Burn'],
                '@customercount': data['Customer Count'],
                "@date": data['Date'],
                "@CompanyCinchyId": companyId
            };

            const queryString = new URLSearchParams(queryParams).toString();
            const url = `${QUERIES.COMPANY_DETAILS.FINANCIALS_ADD}?${queryString}`;

            const { response, status }  = await getData(url, dispatch);
            if(status === STATUSES.SUCCESS) {
                return response;
            }
        } catch (error) {
            throw new Error('Failed to insert financials.');
        }
    }
);

interface AddHeadcountParams {
    data: {
        Headcount: string;
        Date: string;
    },
    companyId: string
}

export const addHeadcount = createAsyncThunk(
    'sourcing/addHeadcount',
    async ({ data, companyId }: AddHeadcountParams, { dispatch }) => {
        try {
            const queryParams = {
                '@headcount': data['Headcount'],
                '@timestamp': data['Date'],
                "@Company": companyId
            };

            const queryString = new URLSearchParams(queryParams).toString();
            const url = `${QUERIES.COMPANY_DETAILS.HEADCOUNT.INSERT}?${queryString}`;

            const { response, status }  = await getData(url, dispatch);
            if(status === STATUSES.SUCCESS) {
                return response;
            }
        } catch (error) {
            throw new Error('Failed to insert headcount.');
        }
    }
);

interface AddCompanyParams {
    data: {
        Email: string;
        'First Name': string;
        'Last Name': string;
        'Primary Contact': string;
        Title: string;
    },
    companyId: string
}

export const addCompanyContacts = createAsyncThunk(
    'sourcing/addCompanyContacts',
    async ({ data, companyId }: AddCompanyParams, { dispatch }) => {
        try {
            const queryParams = {
                '@email': data['Email'],
                '@firstname': data['First Name'],
                '@lastname': data['Last Name'],
                '@primarycontact': data['Primary Contact'],
                '@title': data['Title'],
                "@CompanyCinchyId": companyId
            };

            const queryString = new URLSearchParams(queryParams).toString();
            const url = `${QUERIES.COMPANY_DETAILS.COMPANY_CONTACTS.ADD}?${queryString}`;

            const { response, status }  = await getCompaniesData(url, dispatch);
            if(status === STATUSES.SUCCESS) {
                return response;
            }
        } catch (error) {
            throw new Error('Failed to insert company contacts.');
        }
    }
);

interface AddFinancingParams {
    data: {
        'Date': string;
        'FPC Invested ($M)': string;
        'Miss / Pass / Lost': string;
        'Miss / Pass / Lost Reason': string;
        'Series': string;
        'Total Raised ($M)': string;
        'Round Size ($M)': string;
        'Valuation ($M)': string
    },
    companyName: string
}

export const addFinancing = createAsyncThunk(
    'sourcing/addFinancing',
    async ({ data, companyName }: AddFinancingParams, { dispatch }) => {
        try {
            const queryParams = {
                '@date': data['Date'],
                '@fpcinvested': data['FPC Invested ($M)'],
                '@misspasslost': data['Miss / Pass / Lost'],
                '@misspoastlossreason': data['Miss / Pass / Lost Reason'],
                '@series': data['Series'],
                '@totalfunding': data['Total Raised ($M)'],
                '@totalinvestment': data['Round Size ($M)'],
                '@valuation': data['Valuation ($M)'],
                "@companyname": companyName
            };

            const queryString = new URLSearchParams(queryParams).toString();
            const url = `${QUERIES.COMPANY_DETAILS.FUNDRAISING.ADD}?${queryString}`;

            const { response, status }  = await getData(url, dispatch);
            if(status === STATUSES.SUCCESS) {
                return response;
            }
        } catch (error) {
            throw new Error('Failed to insert financings.');
        }
    }
);

interface UpdateFinancialsParams {
    ARR: string;
    Cash: string;
    'Customer Count': string;
    Date: string;
    'Monthly Burn': string;
    'Table Cinchy Id': string
}

export const updateFinancials = createAsyncThunk(
    'sourcing/updateFinancials',
    async ( data : UpdateFinancialsParams, { dispatch }) => {
        try {
            const queryParams = {
                '@arr': data['ARR'] || "",
                '@cash': data['Cash'] || "",
                '@cashburn': data['Monthly Burn'] || "",
                '@customercount': data['Customer Count'] || "",
                "@date": data['Date'],
                "@tablecinchyid": data['Table Cinchy Id']
            };

            const queryString = new URLSearchParams(queryParams).toString();
            const url = `${QUERIES.COMPANY_DETAILS.FINANCIALS_UPDATE}?${queryString}`;

            const { response, status }  = await getData(url, dispatch);
            if(status === STATUSES.SUCCESS) {
                return response.payload;
            }
        } catch (error) {
            throw new Error('Failed to update financials.');
        }
    }
);

interface UpdateCompanyContactsParams {
    Email: string;
    'First Name': string;
    'Last Name': string;
    'Primary Contact': string;
    Title: string;
    'Table Cinchy Id': string
}

export const updateCompanyContacts = createAsyncThunk(
    'sourcing/updateCompanyContacts',
    async ( data : UpdateCompanyContactsParams, { dispatch }) => {
        try {
            const queryParams = {
                '@email': data['Email'],
                '@firstname': data['First Name'],
                '@lastname': data['Last Name'],
                '@primarycontact': data['Primary Contact'],
                '@title': data['Title'],
                "@tablecinchyid": data['Table Cinchy Id']
            };

            const queryString = new URLSearchParams(queryParams).toString();
            const url = `${QUERIES.COMPANY_DETAILS.COMPANY_CONTACTS.UPDATE}?${queryString}`;

            const { response, status }  = await getCompaniesData(url, dispatch);
            if(status === STATUSES.SUCCESS) {
                return response.payload;
            }
        } catch (error) {
            throw new Error('Failed to update company contacts.');
        }
    }
);

interface UpdateOpportunityParams {
    pipelineStatus: string;
    tablecinchyid: string;
    fpcInvestment: string;
    roundSize: string;
    series: string;
    valuation: string;
    closingDate: string;
  }
  
  export const updateOpportunity = createAsyncThunk(
    "sourcing/updateOpportunity",
    async (data: UpdateOpportunityParams) => {
      try {
        const queryParams = {
          "@closingdate": data["closingDate"],
          "@fpcinvestment": data["fpcInvestment"],
          "@pipelinestatus": data["pipelineStatus"],
          "@roundsize": data["roundSize"],
          "@series": data["series"],
          "@tablecinchyid": data["tablecinchyid"],
          "@valuation": data["valuation"],
        };
  
        const queryString = new URLSearchParams(queryParams).toString();
        const url = `${QUERIES.OPPORTUNITIES.UPDATE}?${queryString}`;
  
        const { response, status } = await getData(url);
        if (status === STATUSES.SUCCESS) {
          return response.payload;
        }
      } catch (error) {
        throw new Error("Failed to delete financials.");
      }
    }
  );

  interface InsertOpportunityParams {
    status: string;
    companyName: string;
    fpcInvestment: string;
    roundSize: string;
    series: string;
    valuation: string;
    closingDate: string;
  }

  export const insertOpportunity = createAsyncThunk(
    "sourcing/insertOpportunity",
    async (data: InsertOpportunityParams) => {
      try {
        const queryParams = {
          "@closingdate": data["closingDate"],
          "@fpcinvestment": data["fpcInvestment"],
          "@status": data["status"],
          "@roundsize": data["roundSize"],
          "@series": data["series"],
          "@companyname": data["companyName"],
          "@valuation": data["valuation"],
        };
  
        const queryString = new URLSearchParams(queryParams).toString();
        const url = `${QUERIES.OPPORTUNITIES.INSERT}?${queryString}`;
  
        const { response, status } = await getData(url);
        if (status === STATUSES.SUCCESS) {
          return response.payload;
        }
      } catch (error) {
        throw new Error("Failed to delete financials.");
      }
    }
  );

interface UpdateFinancingParams {
    'Date': string;
    'FPC Invested ($M)': string;
    'Miss / Pass / Lost': string;
    'Miss / Pass / Lost Reason': string;
    'Series': string;
    'Total Raised ($M)': string;
    'Round Size ($M)': string;
    'Valuation ($M)': string
    'Table Cinchy Id': string
}

export const updateFinancing = createAsyncThunk(
    'sourcing/updateFinancing',
    async ( data : UpdateFinancingParams, { dispatch }) => {
        try {
            const queryParams = {
                '@date': data['Date'] || "",
                '@fpcinvested': data['FPC Invested ($M)'] || "",
                '@misspasslost': data['Miss / Pass / Lost'] || "",
                '@misspasslostreason': data['Miss / Pass / Lost Reason'] || "",
                '@series': data['Series'] || "",
                '@totalfunding': data['Total Raised ($M)'] || "",
                '@totalinvestment': data['Round Size ($M)'] || "",
                '@valuation': data['Valuation ($M)'] || "",
                "@tablecinchyid": data['Table Cinchy Id']
            };

            const queryString = new URLSearchParams(queryParams).toString();
            const url = `${QUERIES.COMPANY_DETAILS.FUNDRAISING.UPDATE}?${queryString}`;

            const { response, status }  = await getData(url, dispatch);
            if(status === STATUSES.SUCCESS) {
                return response.payload;
            }
        } catch (error) {
            throw new Error('Failed to update financings.');
        }
    }
);

interface UpdateHeadcountParams {
    Headcount: string;
    Date: string;
    TableId: string
}

export const updateHeadcount = createAsyncThunk(
    'sourcing/updateHeadcount',
    async ( data : UpdateHeadcountParams, { dispatch }) => {
        try {
            const queryParams = {
                '@headcount': data['Headcount'],
                "@timestamp": data['Date'],
                "@cinchyid": data['TableId']
            };

            const queryString = new URLSearchParams(queryParams).toString();
            const url = `${QUERIES.COMPANY_DETAILS.HEADCOUNT.UPDATE}?${queryString}`;

            const { response, status }  = await getData(url, dispatch);
            if(status === STATUSES.SUCCESS) {
                return response.payload;
            }
        } catch (error) {
            throw new Error('Failed to update headcount.');
        }
    }
);

interface UpdateContactInfoParams {
    cinchyid: string;
    primarycontactfirstname: string;
    primarycontactlastname: string;
    primarycontactposition: string;
    primarycontactemail: string
}

export const updateContactInfo = createAsyncThunk(
    'sourcing/updateContactInfo',
    async ( data : UpdateContactInfoParams, { dispatch }) => {
        try {
            const queryParams = {
                '@cinchyid': data['cinchyid'],
                '@primarycontactfirstname': data['primarycontactfirstname'],
                '@primarycontactlastname': data['primarycontactlastname'],
                '@primarycontactposition': data['primarycontactposition'],
                "@primarycontactemail": data['primarycontactemail'],
            };

            const queryString = new URLSearchParams(queryParams).toString();
            const url = `${QUERIES.COMPANY_DETAILS.CONTACT_UPDATE}?${queryString}`;

            const { response, status }  = await getData(url, dispatch);
            if(status === STATUSES.SUCCESS) {
                return response.payload;
            }
        } catch (error) {
            throw new Error('Failed to update contact info.');
        }
    }
);

interface UpdateContactDateParams {
    cinchyid: string;
    futureContactDate: string;
}

export const updateContactDateParams = createAsyncThunk(
    'sourcing/updateContactDate',
    async ( data : UpdateContactDateParams, { dispatch }) => {
        try {
            let queryParams = {
                '@cinchyid': data['cinchyid'],
            };

            if (data['futureContactDate']) {
                // @ts-ignore
                queryParams['@futureContactDate'] = data['futureContactDate'];
              }

            const queryString = new URLSearchParams(queryParams).toString();
            const url = `${QUERIES.COMPANY_DETAILS.FUTURE_CONTACT_DATE_UPDATE}?${queryString}`;

            const { response, status }  = await getData(url, dispatch);
            if(status === STATUSES.SUCCESS) {
                return response.payload;
            }
        } catch (error) {
            throw new Error('Failed to update future contact date.');
        }
    }
);

interface UpdateFundraisingHistoryParams {
    pitchbookId: string;
    recentFinancing: string;
    date: string;
    amount: string;
    postValuation: string;
    totalRaised: string;
    lastValuation: string;
    lastValuationDate: string
}

export const updateFundraisingHistory = createAsyncThunk(
    'sourcing/updateFundraisingHistory',
    async ( data : UpdateFundraisingHistoryParams, { dispatch }) => {
        try {
            const queryParams = {
                '@pitchbookid': data['pitchbookId'],
                '@lfdescription': data['recentFinancing'],
                '@lfdate': data['date'],
                '@lfamount': data['amount'],
                "@lfvaluation": data['postValuation'],
                '@totalraised': data['totalRaised'],
                '@lkvaluation': data['lastValuation'],
                "@lkvaluationdate": data['lastValuationDate'],
            };

            const queryString = new URLSearchParams(queryParams).toString();
            const url = `${QUERIES.COMPANY_DETAILS.FUNDRAISING_HISTORY_UPDATE}?${queryString}`;

            const { response, status }  = await getData(url, dispatch);
            if(status === STATUSES.SUCCESS) {
                return response.payload;
            }
        } catch (error) {
            throw new Error('Failed to update fundraisingHistory.');
        }
    }
);

interface UpdateOwnersParams {
    affinityId: string;
    owners: string;
}

export const updateOwners = createAsyncThunk(
    'sourcing/updateOwners',
    async ( { affinityId, owners } : UpdateOwnersParams, { dispatch }) => {
        try {
            const queryParams = {
                '@cinchyid': affinityId,
                '@owners': owners,
            };

            const queryString = new URLSearchParams(queryParams).toString();
            const url = `${QUERIES.OWNERS.UPDATE}?${queryString}`;

            const { response, status }  = await getData(url, dispatch);
            if(status === STATUSES.SUCCESS) {
                return response.payload;
            }
        } catch (error) {
            throw new Error('Failed to update owners.');
        }
    }
);

interface UpdateSectorParams {
    cinchyId: string;
    sectors: string;
}

export const updateSector = createAsyncThunk(
    'sourcing/updateSector',
    async ( { cinchyId, sectors } : UpdateSectorParams, { dispatch }) => {
        try {
            const queryParams = {
                '@cinchyid': cinchyId,
                '@sector': sectors,
            };

            const queryString = new URLSearchParams(queryParams).toString();
            const url = `${QUERIES.SECTORS.UPDATE}?${queryString}`;

            const { response, status }  = await getData(url, dispatch);
            if(status === STATUSES.SUCCESS) {
                return response.payload;
            }
        } catch (error) {
            throw new Error('Failed to update sectors.');
        }
    }
);

interface UpdateTagsParams {
    cinchyId: string;
    tags: string;
}

export const updateTags = createAsyncThunk(
    'sourcing/updateTags',
    async ( { cinchyId, tags } : UpdateTagsParams, { dispatch }) => {
        try {
            const queryParams = {
                '@cinchyid': cinchyId,
                '@tags': tags,
            };

            const queryString = new URLSearchParams(queryParams).toString();
            const url = `${QUERIES.TAGS.UPDATE}?${queryString}`;

            const { response, status }  = await getData(url, dispatch);
            if(status === STATUSES.SUCCESS) {
                return response.payload;
            }
        } catch (error) {
            throw new Error('Failed to update tags.');
        }
    }
);

export const updatePeopleOwners = createAsyncThunk(
    'sourcing/updatePeopleOwners',
    async ( { affinityId, owners } : UpdateOwnersParams, { dispatch }) => {
        try {
            const queryParams = {
                '@cinchyid': affinityId,
                '@owners': owners,
            };

            const queryString = new URLSearchParams(queryParams).toString();
            const url = `${QUERIES.OWNERS.UPDATEPEOPLE}?${queryString}`;

            const { response, status }  = await getData(url, dispatch);
            if(status === STATUSES.SUCCESS) {
                return response.payload;
            }
        } catch (error) {
            throw new Error('Failed to update owners.');
        }
    }
);

export const updateInvestorOwners = createAsyncThunk(
    'sourcing/updateInvestorOwners',
    async ( { affinityId, owners } : UpdateOwnersParams, { dispatch }) => {
        try {
            const queryParams = {
                '@cinchyid': affinityId,
                '@owners': owners,
            };

            const queryString = new URLSearchParams(queryParams).toString();
            const url = `${QUERIES.OWNERS.UPDATEINVESTOR}?${queryString}`;

            const { response, status }  = await getData(url, dispatch);
            if(status === STATUSES.SUCCESS) {
                return response.payload;
            }
        } catch (error) {
            throw new Error('Failed to update owners.');
        }
    }
);

export const deleteFinancials = createAsyncThunk(
    'sourcing/deleteFinancials',
    async ( tableCinchyid : string, { dispatch }) => {
        try {
            const queryParams = {
                '@cinchyid': tableCinchyid,
            };

            const queryString = new URLSearchParams(queryParams).toString();
            const url = `${QUERIES.COMPANY_DETAILS.FINANCIALS_DELETE}?${queryString}`;

            const { response, status }  = await getData(url, dispatch);
            if(status === STATUSES.SUCCESS) {
                return response.payload;
            }
        } catch (error) {
            throw new Error('Failed to delete financials.');
        }
    }
);

export const insertSector = createAsyncThunk(
    'sourcing/insertSector',
    async ( sector : string, { dispatch }) => {
        try {
            const queryParams = {
                '@sector': sector,
            };

            const queryString = new URLSearchParams(queryParams).toString();
            const url = `${QUERIES.SECTORS.INSERT}?${queryString}`;

            const { response, status }  = await getData(url, dispatch);
            if(status === STATUSES.SUCCESS) {
                return response.payload;
            }
        } catch (error) {
            throw new Error('Failed to insert sectors.');
        }
    }
);

export const deleteSector = createAsyncThunk(
    'sourcing/deleteSector',
    async ( tableCinchyid : string, { dispatch }) => {
        try {
            const queryParams = {
                '@cinchyid': tableCinchyid,
            };

            const queryString = new URLSearchParams(queryParams).toString();
            const url = `${QUERIES.SECTORS.DELETE}?${queryString}`;

            const { response, status }  = await getAffinityData(url, dispatch);
            if(status === STATUSES.SUCCESS) {
                return response.payload;
            }
        } catch (error) {
            throw new Error('Failed to delete sectors.');
        }
    }
);

export const deleteHeadcount = createAsyncThunk(
    'sourcing/deleteHeadcount',
    async ( tableCinchyid : string, { dispatch }) => {
        try {
            const queryParams = {
                '@cinchyid': tableCinchyid,
            };

            const queryString = new URLSearchParams(queryParams).toString();
            const url = `${QUERIES.COMPANY_DETAILS.HEADCOUNT.DELETE}?${queryString}`;

            const { response, status }  = await getData(url, dispatch);
            if(status === STATUSES.SUCCESS) {
                return response.payload;
            }
        } catch (error) {
            throw new Error('Failed to delete headcount.');
        }
    }
);

export const deleteCompanyContacts = createAsyncThunk(
    'sourcing/deleteCompanyContacts',
    async ( tableCinchyid : string, { dispatch }) => {
        try {
            const queryParams = {
                '@tablecinchyid': tableCinchyid,
            };

            const queryString = new URLSearchParams(queryParams).toString();
            const url = `${QUERIES.COMPANY_DETAILS.COMPANY_CONTACTS.DELETE}?${queryString}`;

            const { response, status }  = await getCompaniesData(url, dispatch);
            if(status === STATUSES.SUCCESS) {
                return response.payload;
            }
        } catch (error) {
            throw new Error('Failed to delete company contacts.');
        }
    }
);

export const deleteFinancing = createAsyncThunk(
    'sourcing/deleteFinancing',
    async ( tableCinchyid : string, { dispatch }) => {
        try {
            const queryParams = {
                '@tablecinchyid': tableCinchyid,
            };

            const queryString = new URLSearchParams(queryParams).toString();
            const url = `${QUERIES.COMPANY_DETAILS.FUNDRAISING.DELETE}?${queryString}`;

            const { response, status }  = await getData(url, dispatch);
            if(status === STATUSES.SUCCESS) {
                return response.payload;
            }
        } catch (error) {
            throw new Error('Failed to delete financings.');
        }
    }
);

export const deletePrimaryCompetitor = createAsyncThunk(
    'sourcing/insertPrimaryCompetitors',
    async ({ id }: any, { dispatch }) => {
        const queryParams = {
            '@cinchyid': String(id)
        };
        const queryString = new URLSearchParams(queryParams).toString();
        const url = `${QUERIES.COMPANY_DETAILS.PRIMARY_COMPETITORS.DELETE}?${queryString}`;
        const { response, status }  = await getData(url, dispatch);
        if (status === STATUSES.SUCCESS) {
            return response;
        }
    }
);

export const addCompanyOutlook = createAsyncThunk(
    'sourcing/addCompanyOutlook',
    async ({ companyId, date, outlook }: CompanyOutlookParams, { dispatch }) => {
        const queryParams = {
            '@Company': String(companyId),
            '@date': date,
            '@outlook': outlook,
        };

        const queryString = new URLSearchParams(queryParams).toString();
        const url = `${QUERIES.COMPANY_DETAILS.COMPANY_OUTLOOK.ADD}?${queryString}`;

        const { response, status } = await getData(url, dispatch);

        if(status === STATUSES.SUCCESS) {
            return response;
        }
    }
);

export const getFinancingSyncedData = createAsyncThunk(
    "sourcing/getFinancingSyncedData",
    async (companyId : number, { dispatch }) => {
      const { response, status } = await getData(`${QUERIES.COMPANY_DETAILS.FINANCING_SYNC.GET}?%40cinchyid=${companyId}`, dispatch);
      if (status === STATUSES.SUCCESS) {
        return response;
      }
    }
);

export const syncFinancingData = createAsyncThunk(
    "sourcing/syncFinancingData",
    async (companyId : number, { dispatch }) => {
      const { response, status } = await getCompaniesData(`${QUERIES.COMPANY_DETAILS.FINANCING_SYNC.UPDATE}?%40cinchyid=${companyId}`, dispatch);
      if (status === STATUSES.SUCCESS) {
        return response;
      }
    }
);

export const addInvestorOutlook = createAsyncThunk(
    'sourcing/addInvestorOutlook',
    async ({ investorId, date, outlook }: InvestorOutlookParams, { dispatch }) => {
        const queryParams = {
            '@Investor': String(investorId),
            '@date': date,
            '@outlook': outlook,
        };

        const queryString = new URLSearchParams(queryParams).toString();
        const url = `${QUERIES.COMPANY_DETAILS.COMPANY_OUTLOOK.ADD_INVESTOR}?${queryString}`;

        const { response, status } = await getData(url, dispatch);

        if(status === STATUSES.SUCCESS) {
            return response;
        }
    }
);

export const updateCompanyOutlook = createAsyncThunk(
    'sourcing/updateCompanyOutlook',
    async ({ companyId, date, outlook }: CompanyOutlookParams, { dispatch }) => {
        const queryParams = {
            '@cinchyid': String(companyId),
            '@date': date,
            '@outlook': outlook,
        };

        const queryString = new URLSearchParams(queryParams).toString();
        const url = `${QUERIES.COMPANY_DETAILS.COMPANY_OUTLOOK.UPDATE}?${queryString}`;

        const { response, status }  = await getData(url, dispatch);
        if (status === STATUSES.SUCCESS) {
            return response;
        }
    }
);

export const updateInternalNotes = createAsyncThunk(
    'sourcing/updateInternalNotes',
    async ({
        companyId,
        differentiation,
        gtm,
        problem,
        team,
    }: UpdateInternalNotesParams, { dispatch }) => {
        const queryParams = {
            '@cinchyid': String(companyId),
            '@differentiation': differentiation,
            '@teamproductstrategy': gtm,
            '@problem': problem,
            '@whydotheylose': team,
        };
        const queryString = new URLSearchParams(queryParams).toString();
        const url = `${QUERIES.COMPANY_DETAILS.INTERNAL_NOTES.UPDATE}?${queryString}`;

        const { response, status }  = await getData(url, dispatch);
        if(status === STATUSES.SUCCESS) {
            return response;
        }
    }
);

export const getBenchmarks = createAsyncThunk(
    'sourcing/getBenchmarks',
    async (_, { dispatch }) => {
        const url = QUERIES.COMPANY_DETAILS.BENCHMARKS.GET;

        const { response, status } = await getData(url);
        if (status === STATUSES.SUCCESS) {
            const result = whitespaceGroupingByRow(response);
            return result;
        }
    }
);

export const getResearch = createAsyncThunk(
    'sourcing/getResearch',
    async (columns: string[], { dispatch }) => {
        const { response, status } = await getData(QUERIES.RESEARCH.SELECT, dispatch);
            if (status === STATUSES.SUCCESS) {
                const data = changeDataShape(response);
                const filteredData = selectNecessaryColumns(data, columns);
                return filteredData;
            }
      }
);

export const getFlags = createAsyncThunk(
    'sourcing/getFlags',
    async (columns: string[], { dispatch }) => {
        const { response, status } = await getData(QUERIES.FLAGS.OVERDUE, dispatch);
            if (status === STATUSES.SUCCESS) {
                const rows = whitespaceGroupingByRow(response);
                const cells = getColumns(response.schema);
                return { rows, cells };
            }
      }
);

export const getPass = createAsyncThunk(
    'sourcing/getPass',
    async (columns: string[], { dispatch }) => {
        const { response, status } = await getData(QUERIES.OPPORTUNITIES.PASS, dispatch);
            if (status === STATUSES.SUCCESS) {
                const result = changeDataShape(response);
                const filteredData = selectNecessaryColumns(result, columns);
                return filteredData;
            }
      }
);

export const getTenPager = createAsyncThunk(
    'sourcing/getTenPager',
    async (columns: string[], { dispatch }) => {
        const { response, status } = await getData(QUERIES.OPPORTUNITIES.TEN_PAGER, dispatch);
            if (status === STATUSES.SUCCESS) {
                const result = changeDataShape(response);
                const filteredData = selectNecessaryColumns(result, columns);
                return filteredData;
            }
      }
);

export const getOnePager = createAsyncThunk(
    'sourcing/getOnePager',
    async (columns: string[], { dispatch }) => {
        const { response, status } = await getData(QUERIES.OPPORTUNITIES.ONE_PAGER, dispatch);
            if (status === STATUSES.SUCCESS) {
                const result = changeDataShape(response);
                const filteredData = selectNecessaryColumns(result, columns);
                return filteredData;
            }
      }
);

export const getFinalPreso = createAsyncThunk(
    'sourcing/getFinalPreso',
    async (columns: string[], { dispatch }) => {
        const { response, status } = await getData(QUERIES.OPPORTUNITIES.FINAL_PRESO, dispatch);
            if (status === STATUSES.SUCCESS) {
                const result = changeDataShape(response);
                const filteredData = selectNecessaryColumns(result, columns);
                return filteredData;
            }
      }
);

export const getComingToMarket = createAsyncThunk(
    'sourcing/getComingToMarket',
    async (columns: string[], { dispatch }) => {
        const { response, status } = await getData(QUERIES.OPPORTUNITIES.COMING_TO_MARKET, dispatch);
            if (status === STATUSES.SUCCESS) {
                const result = changeDataShape(response);
                const filteredData = selectNecessaryColumns(result, columns);
                return filteredData;
            }
      }
);

export const getTracking = createAsyncThunk(
    'sourcing/getTracking',
    async (columns: string[], { dispatch }) => {
        const { response, status } = await getData(QUERIES.OPPORTUNITIES.TRACKING, dispatch);
            if (status === STATUSES.SUCCESS) {
                const result = changeDataShape(response);
                const filteredData = selectNecessaryColumns(result, columns);
                return filteredData;
            }
      }
);


export const getOpportunitiesKanban = createAsyncThunk(
    'sourcing/getOpportunitiesKanban',
    async (_, { dispatch }) => {
        const { response, status } = await getData(QUERIES.OPPORTUNITIES.KANBAN, dispatch);
        if (status === STATUSES.SUCCESS) {
            return whitespaceGroupingByRow(response)
        }
    }
);

export const deleteCompanyOutlook = createAsyncThunk(
    'sourcing/deleteCompanyOutlook',
    async (outlookId: number, { dispatch }) => {
        const queryParams = {
            '@cinchyid': String(outlookId),
        };

        const queryString = new URLSearchParams(queryParams).toString();
        const url = `${QUERIES.COMPANY_DETAILS.COMPANY_OUTLOOK.DELETE}?${queryString}`;

        const { response, status }  = await getData(url, dispatch);
        if (status === STATUSES.SUCCESS) {
            return response;
        }
    }
);

export const getCompanyTags= createAsyncThunk(
    'sourcing/getCompanyTags',
    async (companyId, { dispatch })  => {
        const queryParams = {
            '@cinchyid': String(companyId)
        };

        const queryString = new URLSearchParams(queryParams).toString();
        const url = `${QUERIES.TAGS.GET}?${queryString}`;

        const { response, status }  = await getData(url, dispatch);
        if (status === STATUSES.SUCCESS) {
            const data = whitespaceGroupingByRow(response);
            return data;
        }
    }
);

// TODO CF => Edgar (use getData function, otherwise handle URL properly and use logout in case of 400 error, as done in getData funtcion)
export const getTagOptions = createAsyncThunk(
    'sourcing/getTagOptions',
    async (_, { dispatch }) => {
        const url = `${API_COMPANIES_URL}${QUERIES.TAGS.VIEW_TAG_OPTIONS}`;

        const response = await axios.get(url, { headers: getAuthHeader() });
        if (response) {
            const data = whitespaceGroupingByRow(response.data);
            return data.filter((tag: any) => tag.Tag).reverse();
        }
    }
);

export const getCompanyListedComps = createAsyncThunk(
    'sourcing/getCompanyListedComps',
    async (companyId: number, { dispatch }) => {
        const { response, status }  = await getData(`${QUERIES.COMPANY_DETAILS.LISTED_COMPS}?%40companycinchyid=${companyId}`, dispatch);
        if (status === STATUSES.SUCCESS) {
            const data = changeDataShape(response);
            return data;
        }
    }
);

export const getKeyWords = createAsyncThunk(
    'sourcing/getKeyWords',
    async (_, { dispatch }) => {
        const { response, status } = await getData(QUERIES.KEY_WORDS.SELECT, dispatch);
      if (status === STATUSES.SUCCESS) {
        const result = whitespaceGroupingByRow(response);
        return result;
      }
    }
);

export const getCompanyKeyWords = createAsyncThunk(
    'sourcing/getCompanyKeyWords',
    async (companyId: number, { dispatch }) => {
        const { response, status }  = await getData(`${QUERIES.KEY_WORDS.GET_COMPANY_KEY_WORDS}?%40companycinchyid=${companyId}`, dispatch);
        if(status === STATUSES.SUCCESS) {
            const result = whitespaceGroupingByRow(response);
            return result;
        }
    }
);

export const getCompanyComps = createAsyncThunk(
    'sourcing/getCompanyComps',
    async (companyId: number, { dispatch }) => {
        const { response, status }  = await getData(`${QUERIES.COMPANY_DETAILS.COMPS}?%40cinchyid=${companyId}`, dispatch);
        if (status === STATUSES.SUCCESS) {
            const data = changeDataShape(response);
            return data;
        }
    }
);

export const getCompanyIntelligence = createAsyncThunk(
    'sourcing/getCompanyIntelligence',
    async (companyId: number, { dispatch }) => {
        const { response, status }  = await getData(`${QUERIES.INTELLIGENCE}?%40cinchyid=${companyId}`, dispatch);
        if (status === STATUSES.SUCCESS) {
            const data = changeDataShape(response);
            return data;
        }
    }
);

export const insertCompanyKeyWord = createAsyncThunk(
    'sourcing/insertCompanyKeyWord',
    async ({ companyId, keyWord }: InsertCompanyKeyWordParams, { dispatch }) => {

        const queryParams = {
            '@cinchyid': String(companyId),
            '@Keyword': keyWord,
        };

        const queryString = new URLSearchParams(queryParams).toString();
        const url = `${QUERIES.KEY_WORDS.INSERT}?${queryString}`;
        const { response, status }  = await getData(url, dispatch);
        if (status === STATUSES.SUCCESS) {
            return response;
        }
    }
);

export const updateCompanyKeyWord = createAsyncThunk(
    'sourcing/updateCompanyKeyWord',
    async ({ companyId, relevant }: UpdateCompanyKeyWordParams, { dispatch }) => {

        const queryParams = {
            '@cinchyid': String(companyId),
            '@relevant': relevant,
        };

        const queryString = new URLSearchParams(queryParams).toString();
        const url = `${QUERIES.KEY_WORDS.UPDATE}?${queryString}`;
        const { response, status }  = await getData(url, dispatch);
        if (status === STATUSES.SUCCESS) {
            return response;
        }
    }
);

export const addCompanyTag = createAsyncThunk(
    'sourcing/addCompanyTag',
    async ({ companyId, tag }: any, { dispatch }) => {
        const queryParams = {
            '@Company': String(companyId),
            '@companytag': tag,
        };

        const queryString = new URLSearchParams(queryParams).toString();
        const url = `${QUERIES.TAGS.ADD}?${queryString}`;

        const { response, status }  = await getData(url, dispatch);
        if (status === STATUSES.SUCCESS) {
            return response;
        }
    }
);

export const createTagOption = createAsyncThunk(
    'sourcing/createTagOption',
    async ({ tagName, tagCreator, tagType }: TagOptionPayload, { dispatch }) => {
        const queryParams = {
            '@tag': tagName,
            '@tagcreator': tagCreator,
            '@tagtype': tagType,
        };

        const queryString = new URLSearchParams(queryParams).toString();
        const url = `${QUERIES.TAGS.CREATE_TAG_OPTION}?${queryString}`;

        const { response, status }  = await getData(url, dispatch);
        if (status === STATUSES.SUCCESS) {
            return response;
        }
    }
);

export const deleteTagOption = createAsyncThunk(
    'sourcing/createTagOption',
    async ({ tagId }: {tagId: number}, { dispatch }) => {
        const queryParams = {
            '@cinchyid': String(tagId),
        };

        const queryString = new URLSearchParams(queryParams).toString();
        const url = `${QUERIES.TAGS.DELETE_TAG_OPTION}?${queryString}`;

        const { response, status }  = await getData(url, dispatch);
        if (status === STATUSES.SUCCESS) {
            return response;
        }
    }
);

// TODO: Move this function to Company API's related thunks
export const getCompanyTableColumns = createAsyncThunk(
    'sourcing/getCompanyTableColumns',
    async (_, { dispatch, rejectWithValue }) => {
        const url = QUERIES.COMPANY.COMPANIES_TABLE_COLUMNS;

        const { response, status }  = await getData(url, dispatch);
        if (status === STATUSES.SUCCESS) {
            const data = whitespaceGroupingByRow(response);
            const reorderedData = [
                ...data.filter(item => item.Category.toLowerCase() !== "other"),
                ...data.filter(item => item.Category.toLowerCase() === 'other')
            ]
            return reorderedData;
        }
    }
);

export const deleteCompanyTag = createAsyncThunk(
    'sourcing/createTagOption',
    async ({ tagId }: {tagId: number}, { dispatch, rejectWithValue }) => {
        const queryParams = {
            '@cinchyid': String(tagId),
        };

        const queryString = new URLSearchParams(queryParams).toString();
        const url = `${QUERIES.TAGS.DELETE}?${queryString}`;

        const { response, status }  = await getData(url, dispatch);
        if (status === STATUSES.SUCCESS) {
            return response;
        } else {
            return rejectWithValue('Something get wrong');
        }
    }
);

export const enrichContactData = createAsyncThunk(
    "sourcing/enrich",
    async (id: number, { dispatch }) => {
        try {
            const queryParams = {
                "@cinchyid": String(id),
            };

            const queryString = new URLSearchParams(queryParams).toString();
            const url = `${QUERIES.COMPANY.ENRICH_CONTACT_DATA}?${queryString}`;

            const { response, status } = await getData(url, dispatch);
            if (status === STATUSES.SUCCESS) {
                return response.payload;
            }
        } catch (error) {
            throw new Error("Failed to delete financials.");
        }
    }
);

export const createResearch = createAsyncThunk(
    "sourcing/createResearch",
    async (params: CompanyResearch, { dispatch, rejectWithValue }) => {
        const queryParams = {
            '@author': params.author,
            '@current': String(params.current),
            '@date': params.date,
            '@owner': params.owner,
            '@title': params.title,
            '@type': params.type,
        };

        const queryString = new URLSearchParams(queryParams).toString();
        const url = `${QUERIES.RESEARCH.CREATE}?${queryString}`;

        const { response, status } = await getData(url, dispatch);
        if (status === STATUSES.SUCCESS) {
            return response.payload;
        } else {
            rejectWithValue('Sometning went wrong');
        }
    }
);

export const getListOfTechnologies = createAsyncThunk(
    "sourcing/getListOfTechnologies",
    async (_, { dispatch }) => {
        const url = QUERIES.RESEARCH.GET_LIST_OF_TECHNOLOGIES;
        const { response, status } = await getData(url, dispatch);
        if (status === STATUSES.SUCCESS) {
            const data = whitespaceGroupingByRow(response);
            return data;
        }
    }
);

export const getListOfCompanies = createAsyncThunk(
    "sourcing/getListOfCompanies",
    async (_, { dispatch }) => {
        const url = QUERIES.RESEARCH.GET_LIST_OF_COMPANIES;
        const { response, status } = await getData(url, dispatch);
        if (status === STATUSES.SUCCESS) {
            const data = whitespaceGroupingByRow(response);
            return data;
        }
    }
);

type GetResearchIdPayload = {
    date: string;
    title: string;
};

export const getResearchId = createAsyncThunk(
    "sourcing/getResearchId",
    async ({ date, title }: GetResearchIdPayload, { dispatch, rejectWithValue }) => {
        
        const queryParams = {
            '@date': date,
            '@title': title,
        };
        
        const queryString = new URLSearchParams(queryParams).toString();
        const url = `${QUERIES.RESEARCH.GET_RESEARCH_ID}?${queryString}`;

        const { response, status } = await getData(url, dispatch);

        if (status === STATUSES.SUCCESS) {
            const data = whitespaceGroupingByRow(response);
            
            return data;
        } else {
            return rejectWithValue('Not found');
        }        
    }
);

export const taggingResearchByTechnology = createAsyncThunk(
    "sourcing/taggingResearchByTechnology",
    async ({ researchId, technology}: TaggingResearchByTechnologyPayload, { dispatch, rejectWithValue }) => {
        const queryParams = {
            '@researchid': String(researchId),
            '@technology': technology
        };
        const queryString = new URLSearchParams(queryParams).toString();
        const url = `${QUERIES.RESEARCH.TAGGING_RESEARCH_WITH_TECHNOLOGY}?${queryString}`;

        const { response, status } = await getData(url, dispatch);

        if (status === STATUSES.SUCCESS) {
            return response;
        } else {
            return rejectWithValue('Something went wrong');
        }  
    }
);

export const taggingResearchByCompany = createAsyncThunk(
    "sourcing/taggingResearchByCompany",
    async ({ researchId, companyname}: TaggingResearchByCompanyPayload, { dispatch, rejectWithValue }) => {
        const queryParams = {
            '@researchid': String(researchId),
            '@companyname': companyname
        };
        const queryString = new URLSearchParams(queryParams).toString();
        const url = `${QUERIES.RESEARCH.TAGGING_RESEARCH_WITH_TECHNOLOGY}?${queryString}`;

        const { response, status } = await getData(url, dispatch);

        if (status === STATUSES.SUCCESS) {
            return response;
        } else {
            return rejectWithValue('Something went wrong');
        }  
    }
);

export const deleteResearch = createAsyncThunk(
    "sourcing/deleteResearch",
    async ({ researchId }: any, { dispatch, rejectWithValue }) => {
        const queryParams = {
            '@cinchyid': String(researchId),
        };
        const queryString = new URLSearchParams(queryParams).toString();
        const url = `${QUERIES.RESEARCH.DELETE}?${queryString}`;

        const { response, status } = await getData(url, dispatch);

        if (status === STATUSES.SUCCESS) {
            return response;
        } else {
            return rejectWithValue('Something went wrong');
        }  
    }
);

export const updateResearch = createAsyncThunk(
    "sourcing/updateResearch",
    async (params: CompanyResearch, { dispatch, rejectWithValue }) => {
        const queryParams = {
            '@cinchyid': String(params.cinchyid),
            '@author': params.author,
            '@current': String(params.current),
            '@date': params.date,
            '@owner': params.owner,
            '@title': params.title,
            '@type': params.type,
            '@source': params.source || "",
        };

        const queryString = new URLSearchParams(queryParams).toString();
        const url = `${QUERIES.RESEARCH.UPDATE}?${queryString}`;

        const { response, status } = await getData(url, dispatch);
        if (status === STATUSES.SUCCESS) {
            return response.payload;
        } else {
            rejectWithValue('Sometning went wrong');
        }
    }
);

export const getFinancingsTable = createAsyncThunk(
    "sourcing/getFinancingsTable",
    async (activeTab: string | undefined, { dispatch }) => {
        if(activeTab === 'BY_COMPANY' || !activeTab) {
            activeTab = 'FINANCINGS';
        }

        // @ts-ignore
        const API_PATH = QUERIES.FINANCING[activeTab];

        const { response, status } = await getData(API_PATH, dispatch);
        const { response: editColumns, status: columnsStatus } = await getData(QUERIES.FINANCING.COLUMNS, dispatch);
        if(status === STATUSES.SUCCESS && columnsStatus === STATUSES.SUCCESS) {
            const rows = whitespaceGroupingByRow(response);
            const columns = whitespaceGroupingByRow(editColumns);
            const cells = getColumns(response.schema);

            //TODO add cinchyId if needed
            return {
                rows,
                cells,
                columns,
                activeTab: activeTab
            };
        }
    }
);

export const deleteFinancingsRow = createAsyncThunk(
    "sourcing/deleteFinancingsRow",
    async (tableCinchyId: string) => {
        try {
            const queryParams = {
                "@tablecinchyid": tableCinchyId,
            };

            const queryString = new URLSearchParams(queryParams).toString();
            const url = `${QUERIES.FINANCING.DELETE}?${queryString}`;

            const { response, status } = await getData(url);
            if (status === STATUSES.SUCCESS) {
                return response.payload;
            }
        } catch (error) {
            throw new Error("Failed to delete Financings row.");
        }
    }
);

export const insertFinancingCompany = createAsyncThunk(
    "sourcing/insertFinancing",
    async (data) => {
        try {
            // @ts-ignore
            const queryString = new URLSearchParams(data).toString();
            const { response, status } = await getData(`${QUERIES.FINANCING.INSERT}?${queryString}`);
            if (status === STATUSES.SUCCESS) {
                return response.payload;
            }
        } catch (error) {
            throw new Error("Failed to insert financials row");
        }
    }
);

export const updateCompanyFinancing = createAsyncThunk(
    "sourcing/updateFinancing",
    async (data) => {
        try {
            // @ts-ignore
            const queryString = new URLSearchParams(data).toString();
            const { response, status } = await getData(`${QUERIES.FINANCING.UPDATE}?${queryString}`);
            if (status === STATUSES.SUCCESS) {
                return response.payload;
            }
        } catch (error) {
            throw new Error("Failed to update financials row");
        }
    }
);
 