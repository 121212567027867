import React from 'react';
import { useSelector } from 'react-redux';

import { selectSingleCompanyDetails } from '../../store/sourcing/sourcingSlice';
import StatusSelect from '../StatusSelect';
import OwnersSelect from '../OwnersSelect';

import { Grid } from '@material-ui/core';


import styles from './styles.module.scss';
import {useStatuses} from "../../hooks/useStatus";
import FPCIPriorities from '../FPCIPrio';
import { useFPCIPriorities } from '../../hooks/useFPCIPriorities';


interface CompanyDetailsProps {
  generatePdf: any;
}

const CompanyDetailsHeader = ({ generatePdf }: CompanyDetailsProps) => {
  const companyDetails = useSelector(selectSingleCompanyDetails);
  const ownersInfo = companyDetails.ownersInfo;

  useStatuses();
  useFPCIPriorities();

    return (
    <>
      <div>
        <a
          href="/sourcing/companies/company"
          className={styles.backToCompanyText}
        >
          Back to company database
        </a>
      </div>
      <div className={`${styles.companyDetailsHeader} font-14`}>
      <Grid container={true}>
        {!!ownersInfo.length && (
          <>
          <Grid className={styles.column} xs={5}>
            <div className={styles.headerItem}>
              <span className={styles.companyName}>{ownersInfo[0]["Company Name"]}</span>
              <a href={`http://${ownersInfo[0]["Website"]}`}
              target="_blank">{ownersInfo[0]["Website"]}</a>
              <div className={`${styles.flexItem} mt-2`}>
                  <span className={styles.fpcScore} style={{ backgroundColor: `#${ownersInfo[0]["FPC Score Color"]}`}}>{ownersInfo[0]["FPC Score"]}</span>
              </div>
              <div className="font-bold mt-2"><a className={styles.boldText} href={`${ownersInfo[0]["Hubspot URL"]}`}
              target="_blank">Hubspot</a> | <a className={styles.boldText} href={`${ownersInfo[0]["Affinity URL"]}`}
              target="_blank">Affinity</a></div>
            </div>
            </Grid>
            <Grid className={styles.column} xs={7}>
            <div className={`${styles.ownersBlock}`}>
              <div className={styles.blockItem}>
                <span className="mr-0">Owner</span>
                <div className={styles.owner}>
                  <OwnersSelect
                    affinityId={ownersInfo[0]["Cinchy Id"]}
                    ownersInfo={ownersInfo?.[0]?.Owner?.split("<")?.[0].split(";")}
                  ></OwnersSelect>
                </div>
              </div>
              <div className={styles.blockItem}>
                  <span>Status</span>
                  <div className={styles.status}>
                      <StatusSelect
                          cinchyId={ownersInfo[0]["Cinchy Id"]}
                          name="status"
                          defaultValue={{ label: ownersInfo?.[0]?.Status, value: ownersInfo?.[0]?.Status }}
                      />
                  </div>
              </div>
              <div className={styles.blockItem}>
                  <span>Priority</span>
                  <div className={styles.priority}>
                  <FPCIPriorities cinchyId={ownersInfo[0]["Cinchy Id"]} name="priority" defaultValue={{ label: ownersInfo?.[0]?.Priority, value: ownersInfo?.[0]?.Priority }} />
                  </div>
              </div>
            </div>
            </Grid>
          </>
        )}
        </Grid>
      </div>
    </>
  );
};

export default CompanyDetailsHeader;
