import React, { useState, useCallback, useEffect } from "react";
import { Typography, Button, Tooltip } from "@material-ui/core";
import { AddCircleOutline } from "@material-ui/icons";
import { Table } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { ArrowLeft, ArrowRight } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  addFinancials,
  deleteFinancials,
  getFinancialsColumnWidths,
  updateFinancials,
} from "../../store/sourcing/sourcingThunks";
import DatePicker from "../../example-components/FormsDatepicker/FormsDatepicker1";
import Snackbar from "@material-ui/core/Snackbar";
import { Done, Close, Delete } from "@material-ui/icons";
import styles from "./styles.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { selectFinancialsColumnWidths } from "../../store/sourcing/sourcingSlice";
import {
  formatColumnFormats,
  formatContent,
  checkFormat,
  FORMATTING_TYPES,
  parseDate,
} from "../../utils/formatting";
import Pagination from "../Pagination/Pagination";
import usePagination from "../../hooks/usePagination";

function parseQuarterDate(quarterDate: string) {
  const parts = quarterDate.split(" ");

  if (parts.length !== 2) {
    throw new Error('Invalid input format. Expected "Q YYYY"');
  }

  const quarter = parseInt(parts[0], 10);
  const year = parseInt(parts[1], 10);

  if (isNaN(quarter) || isNaN(year)) {
    throw new Error("Invalid quarter or year format");
  }

  const month = 1 + (quarter - 1) * 3;

  const date = new Date(`${year}-${month.toString().padStart(2, "0")}-1`);

  return date;
}

interface FinancialsOverviewProps {
  tableData: any;
  pagesDisplayed?: boolean;
  handleRefresh: () => void;
  companyId: number;
}

const FinancialsOverview = ({
  tableData,
  pagesDisplayed = true,
  handleRefresh,
  companyId,
}: FinancialsOverviewProps) => {
  const [financialsData, setFinancialsData] = useState([] as any);
  const [rowToUpdate, setRowToUpdate] = useState([] as any);
  const [headers, setHeaders] = useState([] as any);

  useEffect(() => {
    let tableHeaders = tableData.columns;
    if (tableData?.data?.length) {
      tableHeaders = Object.keys(tableData.data[0]);
    }
    setHeaders(tableHeaders);

    setFinancialsData(
      tableData?.data?.map((row: any) => {
        let finalRow = [] as any;
        tableHeaders.map((header: any) => finalRow.push(row[header]));

        return finalRow;
      })
    );
  }, [tableData]);

  const [insertedData, setInsertedData] = useState({} as any);
  const [isInsertOpen, setIsInsertOpen] = useState(false);
  const [updatedRowIndex, setUpdatedRowIndex] = useState(-1);
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [columnFormatValues, setColumnFormatValues] = useState(null);
  const [isUpdateDisabled, setIsUpdateDisabled] = useState(false);
  let columnWidths = useSelector(selectFinancialsColumnWidths);

  const [notificationData, setNotificationData] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    toastrStyle: "",
    message: "This is a toastr/snackbar notification!",
  });

  const { vertical, horizontal, open, toastrStyle, message } = notificationData;
  const { currentPage, rowsPerPage, goToPageValue, goToPage, totalPages, handleRowsPerPageChange, handleGoToPageChange} = usePagination({ rows: financialsData });

  useEffect(() => {
    dispatch(getFinancialsColumnWidths());
  }, []);

  useEffect(() => {
    if (columnWidths?.rows?.length) {
      setColumnFormatValues(formatColumnFormats(columnWidths.rows));
    }
  }, [columnWidths]);

  const handleSelectDate = (date: any) => {
    setSelectedDate(date);
    if(date) {
      if(isNaN(date.getTime())) {
        setIsUpdateDisabled(true)
      } else {
        setIsUpdateDisabled(false)
      }
    } else {
      setIsUpdateDisabled(true)
    }
  };

  const addRow = () => {
    setIsInsertOpen(true);
    let newRow = {} as any;
    headers.slice(1).forEach((header: string) => {
      newRow = { ...newRow, ...{ [header]: "" } };
    });
    setInsertedData(newRow);
  };

  const cancelInsert = () => {
    setIsInsertOpen(false);
    setIsUpdateDisabled(false)
    setSelectedDate(new Date())
  };

  const handleInputChange = useCallback(
    (value: string, columnName: string) => {
      setInsertedData((previous: any) => {
        previous[columnName] = value;
        return { ...previous };
      });
    },
    [setInsertedData]
  );

  const insertRow = useCallback(async () => {
    const formattedDate = parseDate(selectedDate);
    const finalData = insertedData;
    finalData["Date"] = formattedDate;
    setIsInsertOpen(false);
    await dispatch(
      addFinancials({
        data: finalData,
        companyId: companyId.toString(),
      })
    );
    openNotification({
      message: "Data inserted successfully",
      toastrStyle: "toastr-primary",
      vertical: "top",
      horizontal: "right",
    });
    handleRefresh();
  }, [insertedData, addFinancials, selectedDate]);

  const handleRowUpdate = useCallback(
    (index: number) => {
      setRowToUpdate([...financialsData[index]]);
      setUpdatedRowIndex(index);
      const newDate = parseQuarterDate(financialsData[index][1]);
      setSelectedDate(newDate);
    },
    [financialsData]
  );

  const handleRowDelete = useCallback(
    async (index: number) => {
      if (financialsData[index][0]) {
        await dispatch(deleteFinancials(financialsData[index][0]));
      }

      openNotification({
        message: "Data deleted successfully",
        toastrStyle: "toastr-primary",
        vertical: "top",
        horizontal: "right",
      });

      handleRefresh();
    },
    [financialsData]
  );

  const cancelUpdate = useCallback(
    (index: number) => {
      setFinancialsData((previous: any) => {
        previous[index] = rowToUpdate;
        return [...previous];
      });
      setUpdatedRowIndex(-1);
      setIsUpdateDisabled(false)
      setSelectedDate(new Date())
    },
    [rowToUpdate]
  );

  const openNotification = useCallback((newState: any) => {
    setNotificationData({ open: true, ...newState });
    const timer = setTimeout(() => {
      setNotificationData({ ...notificationData, open: false });
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  const saveUpdate = useCallback(
    async (index: number) => {
      let finalData = {} as any;
      headers.map((col: string, i: number) => {
        finalData = { ...finalData, ...{ [col]: financialsData[index][i] } };
      });
      const formattedDate = parseDate(selectedDate);
      finalData["Date"] = formattedDate;

      let isDataFilled = true;

      Object.keys(finalData).forEach((d: any) => {
        if (!finalData[d]) {
          isDataFilled = false;
          return;
        }
      });

      setUpdatedRowIndex(-1);

      await dispatch(updateFinancials(finalData));
      if (isDataFilled) {
        openNotification({
          message: "Data updated successfully",
          toastrStyle: "toastr-primary",
          vertical: "top",
          horizontal: "right",
        });
      }
      handleRefresh();
    },
    [financialsData, selectedDate]
  );

  const handleUpdateChange = useCallback(
    (value: string, rowIndex: number, colIndex: number) => {
      setFinancialsData((previous: any) => {
        previous[rowIndex][colIndex] = value;
        return [...previous];
      });
    },
    [financialsData]
  );

  const handleClose = () => {
    setNotificationData({ ...notificationData, open: false });
  };

  return (
    <>
      <div className={styles.financialsOverviewHeader}>
        <Typography className="mr-2 font-14 font-weight-bold">
          Financials
        </Typography>
        {!isInsertOpen && (
          <div id="blockElement" className={styles.addNewEntryBtnContainer}>
            <Tooltip title="Add new entry" arrow placement="top">
              <Button
                id="blockElement"
                className="btn-primary mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center"
                onClick={addRow}
              >
                <FontAwesomeIcon
                  icon={["fas", "plus"]}
                  className="font-size-sm"
                />
              </Button>
            </Tooltip>
          </div>
        )}
      </div>
      <div className="table-responsive-md mb-spacing-2-x2">
        <Table
          className={`table table-hover table-striped table-bordered ${styles.table}`}
        >
          <thead className={styles.tableHead}>
            <tr className="font-12">
              {headers?.map(
                (header: string, index: number) =>
                  index !== 0 && <th key={index}>{header}</th>
              )}
              <th id="actions" className={`${styles.actionsColumn} font-12`}>
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {financialsData?.slice((currentPage - 1) * rowsPerPage, (currentPage - 1) * rowsPerPage + rowsPerPage)
              ?.map((row: any, index: number) => {
              const rowIndex = (currentPage - 1) * 5 + index;
              return (
                <tr
                  className={
                    (rowIndex !== updatedRowIndex && updatedRowIndex !== -1) ||
                    isInsertOpen
                      ? styles.disabledRow
                      : ""
                  }
                  key={index}
                >
                  {row.map((col: string, colIndex: number) => {
                    if (colIndex !== 0) {
                      return rowIndex !== updatedRowIndex ? (
                        <td key={colIndex} className="font-12">
                          {formatContent(
                            row,
                            col,
                            headers[colIndex],
                            columnFormatValues
                          )}
                        </td>
                      ) : (
                        <td key={colIndex}>
                          {checkFormat(
                            headers[colIndex],
                            columnFormatValues,
                            FORMATTING_TYPES.DATE
                          ) ? (
                            <div className={styles.errorContainer}>
                              <DatePicker
                                handleSelectDate={(date: any) =>
                                  handleSelectDate(date)
                                }
                                selectedDate={selectedDate}
                                isEmpty={isUpdateDisabled}
                              ></DatePicker>
                            <Typography variant="body2" color="error">*</Typography>
                            </div>
                          ) : (
                            <input
                              type="text"
                              value={col}
                              className={styles.input}
                              onChange={(e) =>
                                handleUpdateChange(
                                  e.target.value,
                                  rowIndex,
                                  colIndex
                                )
                              }
                            />
                          )}
                        </td>
                      );
                    }
                  })}
                  <td id="actions" className="text-center">
                    {updatedRowIndex === -1 || rowIndex !== updatedRowIndex ? (
                      <>
                        <img
                          onClick={() => handleRowUpdate(rowIndex)}
                          className={styles.buttonClickable}
                          src="/edit.svg"
                          alt="Company Logo"
                          id="blockElement"
                        />

                        <Delete
                          className={styles.buttonClickable}
                          onClick={() => handleRowDelete(rowIndex)}
                        ></Delete>
                      </>
                    ) : (
                      <>
                        <Button
                          size="small"
                          className={`${"btn-neutral-primary"} mb-2 mr-1`}
                          disabled={isUpdateDisabled}
                          onClick={() => saveUpdate(rowIndex)}
                        >
                          <Done/>
                        </Button>
                        <Button
                          size="small"
                          className={`${"btn-neutral-primary"} mb-2 ml-1`}
                          onClick={() => cancelUpdate(rowIndex)}
                        >
                          <Close/>
                        </Button>
                      </>
                    )}
                  </td>
                </tr>
              );
            })}
            {isInsertOpen && (
              <tr>
                {Object.keys(insertedData).map((col, index) => (
                  <td key={index}>
                    {checkFormat(
                      headers[index + 1],
                      columnFormatValues,
                      FORMATTING_TYPES.DATE
                    ) ? (
                      <div className={styles.errorContainer}>
                        <DatePicker
                        handleSelectDate={(date: any) => handleSelectDate(date)}
                        selectedDate={selectedDate}
                        isEmpty={isUpdateDisabled}
                        ></DatePicker>
                       <Typography variant="body2" color="error">*</Typography>
                      </div>
                    ) : (
                      <input
                        type="text"
                        value={insertedData[col]}
                        className={styles.input}
                        onChange={(e) => handleInputChange(e.target.value, col)}
                      />
                    )}
                  </td>
                ))}
                <td className="text-center">
                  <Button
                    size="small"
                    className={`${"btn-neutral-primary"} mb-2 mr-1`}
                    disabled={isUpdateDisabled}
                    onClick={insertRow}
                  >
                    <Done/>
                  </Button>
                  <Button
                    size="small"
                    className={`${"btn-neutral-primary"} mb-2 ml-1`}
                    onClick={cancelInsert}
                  >
                    <Close/>
                  </Button>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        {pagesDisplayed && (
                    <Pagination goToPage={goToPage} rowsPerPage={rowsPerPage} currentPage={currentPage} goToPageValue={goToPageValue} totalPages={totalPages}
                    handleRowsPerPageChange={handleRowsPerPageChange} handleGoToPageChange={handleGoToPageChange} inCenter={true}/>
        )}
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          key={`${vertical},${horizontal}`}
          open={open}
          classes={{ root: toastrStyle }}
          onClose={handleClose}
          message={message}
        />
      </div>
    </>
  );
};

export default FinancialsOverview;
