import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";

interface RelationshipsProps {
  data: any;
}

const Relationships = ({ data }: RelationshipsProps) => {
  const [investorsTags, setInvestorsTags] = useState([]);
  const [companyTags, setCompanyTags] = useState([]);
  const [customerTags, setCustomerTags] = useState([]);

  useEffect(() => {
    if (data?.length) {
      const companyArr = data?.[0]?.["Company Relationships"]?.split("|");
      const investorArr = data?.[0]?.["Investor Relationships"]?.split("|");
      const customerArr = data?.[0]?.["Customer Relationsihps"]?.split("|");
      setInvestorsTags(investorArr);
      setCompanyTags(companyArr);
      setCustomerTags(customerArr);
    }
  }, [data]);

  return (
    <>
      <div className={styles.investorPeopleTitleContainer}>
        <p className={`${styles.investorPeopleText} font-14 font-weight-bold`}>
          Relationships
        </p>
      </div>
      <div className={styles.singleSection}>
        <div>
          <p className={`${styles.investorPeopleSubText} mt-0 font-12`}>
            Company Relationships
          </p>
          <div className={styles.investorPeopleSubSection}>
            {companyTags?.map((item: string) => (
              <p className={`${styles.investorPeopleTag} font-12`}>{item}</p>
            ))}
          </div>
        </div>
        <div>
          <p className={`${styles.investorPeopleSubText} font-12`}>
            Investor Relationships
          </p>
          <div className={styles.investorPeopleSubSection}>
            {investorsTags?.map((item: string) => (
              <p className={`${styles.investorPeopleTag} font-12`}>{item}</p>
            ))}
          </div>
        </div>
        <div>
          <p className={`${styles.investorPeopleSubText} font-12`}>
            Customer Relationships
          </p>
          <div className={styles.investorPeopleSubSection}>
            {customerTags?.map((item: string) => (
              <p className={`${styles.investorPeopleTag} font-12`}>{item}</p>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Relationships;
