import React from "react";
import styles from "./styles.module.scss";

const StatBox = ({ title, value, valueColor, backgroundColor }: any) => {

  return (
    <div
      className={styles.boxStyle}
      style={{ backgroundColor: backgroundColor || "#f4f4f4" }}
    >
      <div className="font-14">{title}</div>
      <div
        className={`${styles.valueStyle} font-14`}
        style={{ color: valueColor || 'black' }}
      >
        {value}
      </div>
    </div>
  );
};

export default StatBox;
