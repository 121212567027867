import React, { useEffect, useState } from "react";
import StatBox from "./StatBox";
import { useDispatch, useSelector } from "react-redux";
import {
  getActivityData,
  getTopMainData,
} from "../../../../store/dashboard/dashboardThunks";
import {
  selectActivityData,
  selectTopMainData,
} from "../../../../store/dashboard/dashboardSlice";
import TSkeleton from "../../../../components/TSkeleton";
import styles from "./styles.module.scss";

const OwnersInfo = () => {
  const dispatch = useDispatch();
  const [activeCompanyHygieneColor, setActiveCompanyHygieneColor] = useState(
    ""
  );
  const { data, isLoading: isTopMainDataLoading } = useSelector(
    selectTopMainData
  );
  const { data: activityData, isLoading: isActivityDataLoading } = useSelector(
    selectActivityData
  );

  useEffect(() => {
    if (activityData.length) {
      Object.keys(activityData[0]).forEach((key) => {
        if(key.includes("Color")) {
          setActiveCompanyHygieneColor(activityData[0][key])
        }
      });
    }
  }, [activityData]);

  useEffect(() => {
    dispatch(getTopMainData());
    dispatch(getActivityData());
  }, []);

  return (
    <>
      <div className={styles.activitySection}>
        {isActivityDataLoading ? (
          <TSkeleton />
        ) : (
          <div className={styles.secondColumnContainer}>
            <p className="font-18 ml-3">Activity (Last 30 days)</p>
            <div className={styles.sideStatsContainerStyle}>
              {activityData.length > 0 &&
                Object.keys(activityData[0]).slice(0,-1).map((key, index) => (
                  <StatBox
                    key={index}
                    title={key}
                    value={activityData[0][key]}
                    valueColor={index===3 ? `#${activeCompanyHygieneColor}` : null}
                    backgroundColor={null}
                  />
                ))}
            </div>
          </div>
        )}
      </div>
      <h5 className="ml-3 font-18">Hygiene</h5>
      <div className={styles.dashboardStyle}>
        <div className={styles.mainSection}>
          {isTopMainDataLoading ? (
            <TSkeleton />
          ) : (
            <div className={styles.mainGridStyle}>
              {data.length > 0 &&
                (() => {
                  let itemCount = 0;

                  return Object.keys(data[0]).map((key, index) => {
                    if (key.endsWith("Color")) return null;

                    const value = data[0][key];
                    const nextKey = Object.keys(data[0])[index + 1];
                    const hasColor = nextKey && nextKey.includes("Color");
                    const color = hasColor ? `#${data[0][nextKey]}` : null;
                    const backgroundColor = key.includes("FPC")
                      ? "#f7f6ff"
                      : null;

                    itemCount++;

                    return (
                      <React.Fragment key={index}>
                        <StatBox
                          title={key}
                          value={value}
                          valueColor={color}
                          backgroundColor={backgroundColor}
                        />
                        {itemCount % 4 === 0 && (
                          <div className={styles.rowSeparatorStyle}></div>
                        )}
                      </React.Fragment>
                    );
                  });
                })()}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default OwnersInfo;
