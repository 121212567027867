import React, { useCallback, useState } from "react";
import { Button } from "@material-ui/core";
import { useParams } from "react-router";

import CompanyOverview from "../../../components/CompanyOverview";
import CompanyDetailsHeader from "../../../components/CompanyDetailsHeader";

import jsPDF from "jspdf";
import html2canvas from "html2canvas";

import CompaniesComp from "../../../components/CompaniesComp";
import UniversalSearch from "../../../components/UniversalSearch";
import { useOwners } from "../../../hooks/useOwners";
import RecentNews from "../../../components/RecentNews";
import { useStatuses } from "../../../hooks/useStatus";

const GROUPS = {
  OVERVIEW: "overview",
  RESEARCH: "research",
  COMPS: "comps",
  NEWS: "news",
};


const generatePdf = async (page: any) => {
    const pdf = new jsPDF({ orientation: "p", unit: "pt", format: "a4" });
    const element = document.getElementById(page);
    const tableElements = element?.querySelectorAll("#actions");
    const blockElements = element?.querySelectorAll("#blockElement");



    tableElements?.forEach((element) => {
        if (element instanceof HTMLElement) {
            element.style.display = "none";
        }
    });
    blockElements?.forEach((element) => {
        if (element instanceof HTMLElement) {
            element.style.display = "none";
        }
    });

    if (element) {
        const value = element.clientWidth * 1.42

        const pages = Math.ceil(element.clientHeight / value);
        for (let i = 0; i < pages; i++) {
            const canvas = await html2canvas(element, {
                scale: 1,
                windowHeight: value,
                y: i * value,
            });
            const ratio = canvas.height / canvas.width;
            const imgData = canvas.toDataURL("image/jpeg");
            if (i > 0) {
                pdf.addPage();
            }

            const width = pdf.internal.pageSize.getWidth();

            let height = value;
            height = ratio * width;
            pdf.addImage(imgData, "JPEG", 0, 0, width, height);
        }
        pdf.save("output.pdf");
    }

    tableElements?.forEach((element) => {
        if (element instanceof HTMLElement) {
            element.style.display = "table-cell";
        }
    });
    blockElements?.forEach((element) => {
        if (element instanceof HTMLElement) {
            element.style.display = "block";
        }
    });
};

// TODO CF => EL remove after demo
export default function Companies() {
  const [groupBy, setGroupBy] = useState(GROUPS.OVERVIEW);

  const { id } = useParams() as { id: string };

  useOwners();
  useStatuses();

  const handleGrouping = useCallback(
    (group: string) => {
      if (groupBy !== group) {
        setGroupBy(group);
      }
    },
    [groupBy]
  );
  const handleGeneratePdf = () => {
    generatePdf(groupBy)
  }

  return (
    <>
      <UniversalSearch />
      <div className="d-flex pt-4 pb-4">
        <Button
          size="small"
          onClick={() => handleGrouping(GROUPS.OVERVIEW)}
          className={`${
            groupBy === "overview" ? "btn-neutral-primary" : ""
          } mr-2`}
        >
          <span className="btn-wrapper--label">Overview</span>
        </Button>
        {/*<Button*/}
        {/*  size="small"*/}
        {/*  onClick={() => handleGrouping(GROUPS.RESEARCH)}*/}
        {/*  className={`${*/}
        {/*    groupBy === "research" ? "btn-neutral-primary" : ""*/}
        {/*  } mr-2`}*/}
        {/*>*/}
        {/*<div className='divider'></div>*/}
        {/*  <span className="btn-wrapper--label">Research</span>*/}
        {/*</Button>*/}
        <Button
          size="small"
          onClick={() => handleGrouping(GROUPS.COMPS)}
          className={`${
            groupBy === "comps" ? "btn-neutral-primary" : ""
          } mr-2`}
        >
        <div className='divider'></div>
          <span className="btn-wrapper--label">Comps</span>
        </Button>
        <Button
          size="small"
          onClick={() => handleGrouping(GROUPS.NEWS)}
          className={`${
            groupBy === "news" ? "btn-neutral-primary" : ""
          } mr-2`}
        >
        <div className='divider'></div>
          <span className="btn-wrapper--label">Recent news</span>
        </Button>
      </div>
      {groupBy === GROUPS.OVERVIEW && <CompanyOverview id={Number(id)} />}
      {groupBy === GROUPS.NEWS && <RecentNews id={Number(id)} />}
      {/*{groupBy === GROUPS.RESEARCH && <CompaniesResearch id={Number(id)} />}*/}
      {groupBy === GROUPS.COMPS && <CompaniesComp id={Number(id)} />}
      {/*<CompanyDetailsChat cinchyID={id} />*/}
    </>
  );
}
