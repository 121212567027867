import React from "react";
import { Typography } from "@material-ui/core";
import styles from "./styles.module.scss";
import HeadcountHistory from "../HeadcountHistory";

function formatToPercentage(number: Number) {
  if (typeof number !== "number") {
    return "";
  }

  const formattedPercentage = Math.round(number) + "%";
  return formattedPercentage;
}

const OverviewDemographics = ({
  demographics,
  headcountHistory,
  handleRefresh,
  companyId,
}: any) => {
  let filteredDEmographicsData: any = {};

  for (let item of Object.keys(demographics[0])) {
    if (
      item === "Headcount History" ||
      item === "LinkedIn URL" ||
      item === "Pitchbook URL"
    ) {
      continue;
    } else {
      filteredDEmographicsData[item] = demographics[0][item];
    }
  }

  let filteredHeadcountData = headcountHistory.map(
    (headcount: any, index: number) => {
      return {
        TableId: headcount["Table Cinchy Id"],
        Date: headcount.Date,
        Headcount: headcount.Headcount,
      };
    }
  );

  const truncateUrl = (url: string | null, maxLength: number) => {
    if (!url) {
      return "";
    }
  
    return url.length > maxLength ? url.substring(0, maxLength) + "..." : url;
  };

  return (
    <section className={styles.demographics}>
      <Typography className="mb-2 font-14 font-weight-bold">
        Demographics
      </Typography>
      <div className={styles.container}>
        {Object.keys(filteredDEmographicsData).map(
          (demographicItem: any, index) => {
            return (
              <div key={index} className={styles.row}>
                <span className={`${styles.first} font-12`}>
                  {demographicItem}
                </span>
                <div className={`${styles.second} font-12`}>
                  {demographicItem === "YoY Headcount Growth"
                    ? formatToPercentage(
                        filteredDEmographicsData[demographicItem]
                      )
                    : filteredDEmographicsData[demographicItem]}
                </div>
              </div>
            );
          }
        )}
        <div className={`${styles.headcountTable}`}>
          <HeadcountHistory
            tableData={filteredHeadcountData}
            companyId={companyId}
            handleRefresh={handleRefresh}
          ></HeadcountHistory>
        </div>
        <div className={`${styles.row} ${styles.link}`}>
          <span className={`${styles.linkTitle} font-12`}>LinkedIn URL</span>
          <span className={`${styles.linkData} font-12`}>
            <a href={demographics[0]["LinkedIn URL"]} target="_blank">
              {truncateUrl(demographics[0]["LinkedIn URL"], 25)}
            </a>
          </span>
        </div>
        <div className={`${styles.row} ${styles.link}`}>
          <span className={`${styles.linkTitle} font-12`}>Pitchbook URL</span>
          <div className={`${styles.linkData} font-12`}>
            <a href={demographics[0]["Pitchbook URL"]} target="_blank">
              {truncateUrl(demographics[0]["Pitchbook URL"], 25)}
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OverviewDemographics;
