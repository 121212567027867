import { DEFAULT_COLUMNS } from '../constants';


 export function reShapeOwner(owner: string) {
  if(owner) {
    let ownersWithMails = owner.split(';');
    return ownersWithMails.map(ownerAndMail => ownerAndMail.split(' <')?.[0])
  }
  return owner;
}

export function numberWithCommas(number: number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const changeDataShape = (data: any) => {
  let newData = { rows: [] as any, cells: [] };
  newData.cells = data.schema.map((column: any) => {
    const labelValue = column.columnName;
    return { label: labelValue };
  });
  data.data.map((row: any, i: number) => {
    let newObj = {};
    row.map((item: any, index: number) => {
      let key = newData?.cells[index]["label"] as string;
      key = key.toLowerCase().replace(" ", "_");
      const tempObject = { [key]: item };
      newObj = { ...newObj, ...tempObject };
    });
    newObj = { ...newObj, ...{id: i} };
    // @ts-ignore
    newObj.owner = reShapeOwner(newObj.owner);
    newData.rows.push(newObj);
  });
  return newData;
};

export const selectNecessaryColumns = (data: any, valuesToSelect: string[]) => {
  let filteredData = { rows: [] as any, cells: [] };
  let selectedColumns: string[] = [];

  if (!valuesToSelect.length) {
    selectedColumns = DEFAULT_COLUMNS;
  } else {
    selectedColumns = valuesToSelect;
  }
  
  filteredData.cells = data.cells.filter((column: any) => {
    return selectedColumns.includes(column.label.toLowerCase());
  });
  filteredData.rows = data.rows.map((row: any) => {
    let filteredRows = {};
    selectedColumns.map((key) => {
      filteredRows = {
        ...filteredRows,
        ...{ [key]: row[key.toLowerCase().replace(" ", "_")] },
      };
    });
    return filteredRows;
  });
  return filteredData;
};

export const changeBoardColumnNames = (data: any) => {
  const valuesToSelect = [
    "Person Name",
    "Full Title",
    "Board Representing Name",
    "Board Start",
  ];
  const newData = data.map((row: any) => {
    let filteredRows = {};
    valuesToSelect.map((key) => {
      let newKey = key;
      let newValue = row[key];
      if (key === "Person Name") {
        newKey = "Name";
      } else if (key === "Full Title") {
        newKey = "Title";
      } else if (key === "Board Representing Name") {
        newKey = "Board Rep Company";
      } else if (key === "Board Start") {
        if (newValue) {
          const inputDate = new Date(newValue);
          const formattedDate = inputDate.toLocaleDateString("en-US");
          newValue = formattedDate;
        }
      }
      filteredRows = {
        ...filteredRows,
        ...{ [newKey]: newValue },
      };
    });
    return filteredRows;
  });

  return newData;
};

export const changeFinancialsColumnNames = (data: any) => {
  const valuesToSelect = [
    "Table Cinchy Id",
    "Date",
    "ARR",
    "Customer Count",
    "Cash",
    "Monthly Burn",
  ];
  const newData = data.map((row: any) => {
    let filteredRows = {};
    valuesToSelect.map((key) => {
      let newKey = key;
      let newValue = row[key];
      if (key === "Customer Count") {
        newKey = "Customers";
      }
      if (key === "Table Cinchy Id") {
        newKey = "TableId";
      }
      filteredRows = {
        ...filteredRows,
        ...{ [newKey]: newValue },
      };
    });
    return filteredRows;
  });

  return newData;
};

export const selectDataByColumns = (
  selectedData: any,
  selectedColumns: any,
  newColumnNames?: any
) => {
  const cells = [];

  for (const item of selectedData) {
    const rowData: any = {};

    for (const [index, key] of selectedColumns.entries()) {
      if (newColumnNames) {
        rowData[newColumnNames[index]] = item[key];
      } else if (item[key] !== undefined) {
        rowData[key] = item[key];
      } else {
        rowData[key] = null;
      }
    }

    cells.push(rowData);
  }

  return cells;
};

export interface IOutlook {
  'Company Cinchy Id': number;
  'Company Name': string;
  'Table Cinchy Id': number;
  Date: string;
  Outlook: string;
  Author: string
}

export const getCompanyOutlooksByName = (companiesOutlooks: IOutlook[], companyName: string): IOutlook[] => {
  return companiesOutlooks.filter((outlook: IOutlook) => outlook['Company Name'] === companyName);
}

export const selectColumns = (data: any, valuesToSelect: string[]) => {
  let filteredData = { rows: [] as any, cells: [] };
  let selectedColumns: string[] = [];

  if (!valuesToSelect.length) {
    selectedColumns = DEFAULT_COLUMNS;
  } else {
    selectedColumns = valuesToSelect;
  }
  
  filteredData.cells = data.cells.filter((column: any) => {
    return selectedColumns.includes(column.label.toLowerCase());
  });
  filteredData.rows = data.rows.map((row: any) => {
    let filteredRows = {};
    selectedColumns.map((key) => {
      filteredRows = {
        ...filteredRows,
        ...{ [key]: row[key.toLowerCase().replace(" ", "_")] },
      };
    });
    return filteredRows;
  });
  return filteredData;
};

export const numberFormatter = (num: string) => {
  let number = Number(num);
  if (number >= 1000000) {
    return (number / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
  }
  if (number >= 1000) {
    return (number / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
  }
  return number;
}

export function formatNumberInMillions(number: number) {
  if (number >= 1000000000) {
    const billion = (number / 1000000000).toFixed(2);
    return billion.replace(/\.00$/, '') + "B";
  } else if (number >= 1000000) {
    const million = (number / 1000000).toFixed(2);
    return million.replace(/\.00$/, '') + "M";
  } else {
    return number.toLocaleString();
  }
}

export function generateOptions(array: any[], specialOptions: string[]) {
  let options = Object.keys(array?.[0]).reduce((curr, item) => {
    // @ts-ignore
    curr[item] = new Set([]);
    return curr;
  }, {})
  // @ts-ignore
  return array.reduce((current, element) => {
    Object.entries(element).forEach(([key, value]) => {
      if(value) {
        if(specialOptions.includes(key)) {
          // @ts-ignore
          current[key]?.add(...(value?.split(',') || value))
        } else {
          current[key]?.add(value);
        }
      }
    })
    return current;
  }, options)
}