import { FieldHookConfig, useField } from 'formik';
import React, {Dispatch, FC, SetStateAction, useMemo, useState} from 'react';
import Select from 'react-select';

export type IOption = { label: string; value: string | number };

const customStyles = {
    valueContainer: (provided: any) => ({
        ...provided,
        fontSize: 12,
    }),
    option: (provided: any) => ({
        ...provided,
        fontSize: 12,
    }),
    menuList: (provided: any) => ({
        ...provided,
        maxHeight: 150,
    }),
};

export interface ISingleSelect {
    options: Record<string, IOption>;
    name: string;
    onChange: Dispatch<SetStateAction<string>> | ((value: string | number | null) => void)
    onBlur?(): void;
    label?: string;
    value?: IOption['value'];
    defaultValue?: IOption;
    style?: React.CSSProperties;
    required?: boolean | null;
    menuPlacement?: string;
}

export const StatusSelect: React.FC<ISingleSelect> = ({
    options = {},
    name,
    defaultValue,
    onChange,
    }) => {

    const [selectedOption, setSelectedOption] = useState(defaultValue || { label: '', value: '' });

    const handleChange = (option: any) => {
        console.log('selectedRowsIds', option);
        onChange(option.value);
        setSelectedOption(option)
    }


    return (
        <div style={{ margin: '5px 0', width: '100%' }}>
            <Select
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 4,
                    colors: {
                        ...theme.colors,
                        primary25: 'rgba(110, 60, 250, 0.05)',
                        primary: '#6e3cfa',
                    },
                })}
                styles={customStyles}
                name={name}
                // @ts-ignore
                options={Object.values(options)}
                isMulti={false}
                isSearchable
                value={selectedOption}
                onChange={handleChange}
            />
        </div>
    );
};

export const SingleSelect: React.FC<ISingleSelect> = ({
      options = {},
      name,
      onChange,
      label,
      value,
      defaultValue,
      required = false,
      menuPlacement = 'bottom',
      ...props
                                                      }) => {
    const computedDefaultValue = useMemo(() => {
        if ((value === 0 || value) && value in options) return options[value];

        // @ts-ignore
        return defaultValue && defaultValue in options ? options[defaultValue] : { label: '', value: '' };
    }, [defaultValue, options, value]);


    const labelElement = label && (
        <span
            style={{
                marginBottom: 4,
            }}
        >
            {label}
        </span>
    );
    const requiredElement = required && (
        <span
            style={{
                marginBottom: 4,
                marginLeft: 3,
                color: value ? '#646464' : 'red',
            }}
        >
            *
        </span>
    );

    return (
        <div style={{ margin: '5px 0' }}>
            <div className='d-flex'>
                {labelElement}
                {requiredElement}
            </div>
            <Select
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 4,
                    colors: {
                        ...theme.colors,
                        primary25: 'rgba(110, 60, 250, 0.05)',
                        primary: '#6e3cfa',
                    },
                })}
                styles={{
                    input: (providedValue) => ({
                        ...providedValue,
                    }),
                    placeholder: (providedValue) => ({
                        ...providedValue,
                    }),
                }}
                name={name}
                // @ts-ignore
                options={Object.values(options)}
                isMulti={false}
                isSearchable
                // @ts-ignore
                menuPlacement={menuPlacement ? menuPlacement : 'bottom'}
                value={defaultValue}
                onChange={(selected) => {
                    // @ts-ignore
                    if (!selected) return onChange(null);
                    // @ts-ignore
                    return onChange(selected.value);
                }}
                {...props}
            />
        </div>
    );
};

export const SingleSelectFormik: FC<ISingleSelect & FieldHookConfig<string>> = (props) => {
    // @TODO: figure out type
    // @ts-ignore
    const [field, , helpers] = useField(props.field);

    return (
        <SingleSelect
            {...props}
            value={field.value}
            onBlur={() => {
                helpers.setTouched(true);
                props.onBlur && props.onBlur();
            }}
            onChange={(selectedValues: any) => {
                helpers.setValue(selectedValues);
                // @ts-ignore
                props.onChange && props.onChange(selectedValues);
            }}
        />
    );
};