import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import GenericTable from '../../../../components/GenericTable';
import TSkeleton from '../../../../components/TSkeleton';
import {
  selectCategoryCoverage} from '../../../../store/dashboard/dashboardSlice';
import {
  getCategoryCoverage} from '../../../../store/dashboard/dashboardThunks';

import styles from './styles.module.scss';
import usePagination from '../../../../hooks/usePagination';
import Pagination from '../../../../components/Pagination/Pagination';
import { defaultColumn } from '../../../Sourcing/utils';

export default function CategoryCoverage() {
  const dispatch = useDispatch();
    const { rows, cells, isLoading } = useSelector(selectCategoryCoverage);

    useEffect(() => {
        dispatch(getCategoryCoverage());
    }, [])
   
    const getCellStyle = (cell: any, index: number) =>  {
      return cell==='Coverage %' ? {
      color: `#${rows[(currentPage - 1) * 5 + index]['Coverage % Text Color']}`,
  } : defaultColumn}
  
    const renderTCell = useCallback(
        (column, row) => row[column],[])

    const { currentPage, goToPageValue, goToPage, totalPages, handleRowsPerPageChange, handleGoToPageChange} = usePagination({ rows, rowsPerPageValue : 5});

    const rowsToDraw = useMemo(() => rows?.slice(
        (currentPage - 1) * 5,
        (currentPage - 1) * 5 + 5
    ), [rows, currentPage])

  return (
    <>
      {isLoading ? <TSkeleton/> :
            <div className={`${styles.coverageTable} table-responsive-md mb-spacing-2-x2 ml-3`}>
                <GenericTable
                    title={`High Priority Coverage Areas (${rows?.length} results)`}
                    rows={rowsToDraw}
                    fixedColumnHint=""
                    headCells={cells?.slice(0,-1)}
                    tableActions={null}
                    getHeadCellStyle={null}
                    getCellStyle={getCellStyle}
                    renderCell={renderTCell}
                    getActionsCell={null}
                    searchBar={null}
                    scrollable={true}
                >
                    <Pagination
                        goToPage={goToPage}
                        rowsPerPage={5}
                        currentPage={currentPage}
                        goToPageValue={goToPageValue}
                        totalPages={totalPages}
                        handleRowsPerPageChange={handleRowsPerPageChange} 
                        handleGoToPageChange={handleGoToPageChange}
                    />
                </GenericTable>
            </div>
          }

    </>
  );
}
