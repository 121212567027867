import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getData } from '../../api/sourcing';
import { QUERIES, STATUSES } from '../../constants';
import { whitespaceGroupingByRow } from '../../utils/TGroping';
import { StatusSelect } from '../SingleSelect/SIngleSelect';


const getCompanyNameOptions = async ({ dispatch }) => {
    const { status, response  } = await getData(`${QUERIES.UNIVERSAL_SEARCH}`, dispatch)
    if(status === STATUSES.SUCCESS) {
        return whitespaceGroupingByRow(response);
    }
}

function CompanyNameSelect({ setName }) {
    const [options, setOptions] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        getCompanyNameOptions({ dispatch }).then((res) => {
            setOptions(res.map(item => ({ value: `${item['Company Name']}`, label: `${item['Company Name']} | ${item['Company Website']}` })))
        })
    }, [])

    return (
        <>
            <StatusSelect name='Company name' options={options} onChange={setName} />
        </>
    );
}

export default CompanyNameSelect;